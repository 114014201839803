import { FormattedMessage } from 'react-intl';
import Label from './Label';

// Define the FraudScore categories with labels and ranges
export const FraudScore = Object.freeze({
  SAFE: { label: 'Safe', min: 0, max: 20, color: 'success' },
  LOW_RISK: { label: 'Low Risk', min: 20, max: 50, color: 'primary' },
  MEDIUM_RISK: { label: 'Medium Risk', min: 50, max: 75, color: 'warning' },
  HIGH_RISK: { label: 'High Risk', min: 75, max: 100, color: 'error' }
});

// Function to get the fraud score category based on a given score
function getFraudScoreCategory(score) {
  if (score >= FraudScore.SAFE.min && score <= FraudScore.SAFE.max) {
    return FraudScore.SAFE;
  }
  if (score >= FraudScore.LOW_RISK.min && score <= FraudScore.LOW_RISK.max) {
    return FraudScore.LOW_RISK;
  }
  if (
    score >= FraudScore.MEDIUM_RISK.min &&
    score <= FraudScore.MEDIUM_RISK.max
  ) {
    return FraudScore.MEDIUM_RISK;
  }
  if (score >= FraudScore.HIGH_RISK.min) {
    return FraudScore.HIGH_RISK;
  }
  return null;
}

// Component to display the fraud score label
export default function FraudScoreLabel({ score }) {
  const category = getFraudScoreCategory(score);

  return category ? (
    <Label color={category.color}>
      <FormattedMessage id={category.label} defaultMessage={category.label} />
    </Label>
  ) : null;
}
