import SideBySideView from 'src/views/SideBySideView';
import Content from './Content';
import LoginCard from './LoginCard';

export default function index() {
  return (
    <SideBySideView hideFooterForce>
      <Content />
      <LoginCard />
    </SideBySideView>
  );
}
