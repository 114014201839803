import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

import Logo from 'src/components/Logo';
import { FormattedMessage } from 'react-intl';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: <FormattedMessage id="login_hero_title1" />,
    description: <FormattedMessage id="login_hero_subtitle1" />
  },
  {
    icon: <ConstructionRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: <FormattedMessage id="login_hero_title2" />,
    description: <FormattedMessage id="login_hero_subtitle2" />
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: <FormattedMessage id="login_hero_title3" />,
    description: <FormattedMessage id="login_hero_subtitle3" />
  },
  {
    icon: <AutoFixHighRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: <FormattedMessage id="login_hero_title4" />,
    description: <FormattedMessage id="login_hero_subtitle4" />
  }
];

export default function Content() {
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        alignSelf: 'start',
        gap: 4,
        maxWidth: 450
      }}
    >
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Logo isNegativeMode />
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography
              gutterBottom
              sx={{ fontWeight: 'medium', color: 'text.primary' }}
            >
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
      <Stack direction="row" sx={{ gap: 2 }}>
        <img
          class="reward"
          src="https://www.tracknow.io/images/rate-g2-cap3.webp?r=1"
          alt="Quality Reward"
          style={{ width: '100%' }}
        />
      </Stack>
    </Stack>
  );
}
