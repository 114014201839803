import { yupResolver } from '@hookform/resolvers/yup'; // Import yupResolver
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createCampaign,
  getCampaignCategoriesOptions,
  updateCampaignGeneral
} from 'src/actions/campaignActions';
import WYSIWYGEditor from 'src/components/DraftEditorWrapper';
import FilesDropzone from 'src/components/FilesDropzone';
import WithKeyboardDatePicker from 'src/components/WithKeyboardDatePicker';
import { companySelector } from 'src/selectors/selectors';
import { PrivacyEnum, StatusEnum } from 'src/utils/enums/campaignsEnums';
import { CampaignTabsEnum, noImageRelativePath } from 'src/utils/staticObjects';
import { displayUpdateSnackbar } from 'src/utils/utils';
import * as yup from 'yup';
import {
  getAllCurrencies,
  getCountryOptions
} from '../../../../../actions/companyActions';
import FlagIcon from '../../../../../components/FlagIcon';
import {
  filterCountryOptions,
  groupCountriesByType
} from '../../../../../utils/countryUtils';
import { FraudScore } from 'src/components/FraudScoreLabel';

const filterCurrenciesOptions = (options, state) => {
  const escapedInputValue = state?.inputValue
    ? state.inputValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
    : '';

  const inputRegex = new RegExp(escapedInputValue, 'i');
  // Filter options to exclude the already selected countries
  return options
    .filter(option => {
      const optionName = option?.name || '';
      const optionCode = option?.code || '';
      return inputRegex.test(optionName) || inputRegex.test(optionCode);
    })
    .sort((a, b) => {
      // Compare isGroup property (true comes before false)
      if (a.isGroup === b.isGroup) {
        // If isGroup is the same, compare by name in ascending order
        return a.name.localeCompare(b.name);
      }
      // Compare isGroup property (true comes before false)
      return a.isGroup ? -1 : 1;
    });
};

function CampaignGeneralSettingsForm({
  campaignGeneralSettingsDto,
  campaignSerialOid,
  saveAllPixels,
  fetchCampaignData
}) {
  const { hideAdvancedTrackingLogSettings } = useSelector(companySelector);

  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currencies, setCurrencies] = useState([
    { oid: 1, name: 'USD - United States Dollar' }
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const intl = useIntl();

  const companyAdvertisers = useSelector(state => state.company.advertisers);
  const companyTrafficSources = useSelector(
    state => state.company.trafficSources
  );

  useEffect(() => {
    getCountryOptions().then(r => {
      setCountries(r?.data);
    });
    getCampaignCategoriesOptions().then(r => {
      setCategories(r ?? []);
    });
    getAllCurrencies().then(currenciesData => {
      if (currenciesData) {
        setCurrencies(currenciesData);
      }
    });
  }, []);

  const campaignPayoutsSettingsSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required('Title is required'),
        tax: yup
          .number()
          .required()
          .min(
            0,
            intl.formatMessage(
              { id: 'number_must_be_between' },
              { min: 0, max: 100 }
            )
          )
          .max(
            100,
            intl.formatMessage(
              { id: 'number_must_be_between' },
              { min: 0, max: 100 }
            )
          ),
        priority: yup
          .number()
          .integer('Must be an integer')
          .min(0, 'Must be greater than or equal to 0')
          .max(1000, 'Must be less than or equal to 1000')
      }),
    []
  );

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(campaignPayoutsSettingsSchema),
    shouldFocusError: true,
    defaultValues: {
      ...campaignGeneralSettingsDto,
      saveAllPixels,
      campaignTrafficSources:
        campaignGeneralSettingsDto?.campaignTrafficSources?.map(
          o => o.companyTrafficSourceOid
        ) ?? [],
      countries: campaignGeneralSettingsDto?.countries ?? [],
      disallowedCountries:
        campaignGeneralSettingsDto?.disallowedCountries ?? [],
      categories: campaignGeneralSettingsDto?.categories ?? [],
      revisionSwitch: campaignGeneralSettingsDto?.revisionPercentage != null,
      defaultCurrency: campaignGeneralSettingsDto?.defaultCurrency || null
    }
  });
  const baseImagesUrl = process.env.REACT_APP_CAMPAIGN_LOGO_BASE_URL;

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const featured = watch('featured');
  const calculateTax = watch('calculateTax');
  const saveAllPixelsForm = watch('saveAllPixels');
  const imgUrl = `${baseImagesUrl}${campaignGeneralSettingsDto?.logoFilePath ??
    noImageRelativePath}`;
  const onSubmit = data => {
    if (campaignSerialOid)
      updateCampaignGeneral(campaignSerialOid, data)
        .then(() => {
          displayUpdateSnackbar(enqueueSnackbar, intl, true);
          fetchCampaignData();
        })
        .catch(e => {
          if (e?.response?.status === 409) {
            displayUpdateSnackbar(
              enqueueSnackbar,
              intl,
              false,
              e?.response?.data?.message
            );
          } else {
            displayUpdateSnackbar(enqueueSnackbar, intl, false);
          }
        });
    else
      createCampaign(data)
        .then(campaignSerialOid => {
          displayUpdateSnackbar(enqueueSnackbar, intl, true);
          history.push(
            `/app/management/campaign/${campaignSerialOid}/${CampaignTabsEnum.CampaignDetails.value}`
          );
        })
        .catch(e => {
          if (e?.response?.status === 409) {
            displayUpdateSnackbar(
              enqueueSnackbar,
              intl,
              false,
              e?.response?.data?.message
            );
          } else {
            displayUpdateSnackbar(enqueueSnackbar, intl, false);
          }
        });
  };

  const isResetLogo = watch('resetLogo');
  const revisionSwitch = watch('revisionSwitch');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ display: 'flex', gap: 3, flexDirection: 'column', p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={intl.formatMessage({ id: 'title' })}
              fullWidth
              {...register('title')}
              error={!!errors?.title} // Check for errors
              helperText={errors?.title?.message} // Display error message
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="advertiser">
                <FormattedMessage id="advertiser" />
              </InputLabel>
              <Controller
                name="advertiserSerialOid"
                control={control}
                defaultValue={companyAdvertisers?.[0]?.advertiserSerialOid}
                render={({ field }) => (
                  <Select
                    label="advertiser"
                    labelId="advertiser-label"
                    id="advertiser"
                    {...field}
                  >
                    {companyAdvertisers?.map(value => (
                      <MenuItem
                        key={value?.advertiserSerialOid}
                        value={value?.advertiserSerialOid}
                      >
                        {value?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ pb: 1 }} htmlFor="description">
              <FormattedMessage id="description" />
            </InputLabel>

            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => <WYSIWYGEditor {...field} />}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel sx={{ pb: 1 }} htmlFor="campaignTerms">
              <FormattedMessage id="campaign_terms" />
            </InputLabel>
            <Controller
              name="campaignTerms"
              control={control}
              defaultValue=""
              render={({ field }) => <WYSIWYGEditor {...field} />}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FilesDropzone
                field="generalSettingsIODocument"
                setValue={setValue}
                accept=".xls, .xlsx, .pdf, .txt, image/*"
                header={intl.formatMessage({ id: 'io_document' })}
              />
              <FilesDropzone
                isCrop={true}
                field="generalSettingsCampaignLogo"
                setValue={setValue}
                header={intl.formatMessage({ id: 'logo' })}
                maxHeight={50}
              />
            </Box>
            {!!campaignGeneralSettingsDto?.logoFilePath && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button onClick={() => setValue('resetLogo', !isResetLogo)}>
                  <FormattedMessage id={!isResetLogo ? 'reset_logo' : 'undo'} />
                </Button>
                {!isResetLogo && (
                  <img
                    src={imgUrl}
                    alt={intl.formatMessage({
                      id: 'picture_unavailable'
                    })}
                    style={{ maxHeight: 45, maxWidth: 200, marginTop: 3 * 8 }}
                  />
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="status">
                <FormattedMessage id="status" />
              </InputLabel>
              <Controller
                name="status"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Select
                    label={intl.formatMessage({ id: 'status' })}
                    labelId="status-label"
                    id="status"
                    {...field}
                  >
                    {Object.values(StatusEnum).map(value => (
                      <MenuItem key={value.value} value={value.value}>
                        {intl.formatMessage({ id: value.key })}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="privacy">
                <FormattedMessage id="privacy" />
              </InputLabel>
              <Controller
                name="privacy"
                control={control}
                defaultValue={'PUBLIC'}
                render={({ field }) => (
                  <Select
                    label={intl.formatMessage({ id: 'privacy' })}
                    labelId="status-label"
                    id="status"
                    {...field}
                  >
                    {Object.entries(PrivacyEnum).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body1">
                            {intl.formatMessage({ id: value?.label })}
                          </Typography>
                          {!!value?.sub && (
                            <FormHelperText
                              style={{
                                marginTop: '4px',
                                maxWidth: '400px',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap'
                              }}
                            >
                              {value?.sub}
                            </FormHelperText>
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="status">
                <FormattedMessage id="traffic_sources" />
              </InputLabel>
              <Controller
                name="campaignTrafficSources"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    label={<FormattedMessage id="traffic_sources" />}
                    labelId="campaignTrafficSources-label"
                    id="campaignTrafficSources"
                    {...field}
                    multiple
                  >
                    {companyTrafficSources.map(value => (
                      <MenuItem key={value.oid} value={value.oid}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          {/* First Field */}
          <Grid item xs={6}>
            <CustomDatePickerWrapper
              name="startDate"
              labelKey="start_date"
              control={control}
              setValue={setValue}
              error={errors?.startDate} // Check for errors
              value={startDate}

              // Other props for the date picker
            />{' '}
          </Grid>

          {/* Second Field */}
          <Grid item xs={6}>
            <CustomDatePickerWrapper
              name="endDate"
              labelKey="end_date"
              control={control}
              setValue={setValue}
              error={errors?.endDate} // Check for errors
              minDate={startDate}
              value={endDate}
              // Other props for the date picker
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="tax"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  label={intl.formatMessage({ id: 'tax' })}
                  fullWidth
                  type="number"
                  onWheel={event => {
                    event.preventDefault();
                  }}
                  inputProps={{
                    maxLength: 13,
                    step: '0.01'
                  }}
                  {...field}
                  error={!!errors?.tax} // Check for errors
                  helperText={errors?.tax?.message} // Display error message
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="priority"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  label={intl.formatMessage({ id: 'priority' })}
                  fullWidth
                  type="number"
                  onWheel={event => {
                    event.preventDefault();
                  }}
                  inputProps={{
                    maxLength: 13,
                    step: '1'
                  }}
                  {...field}
                  error={!!errors?.priority} // Check for errors
                  helperText={errors?.priority?.message} // Display error message
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id="categories"
              // filterOptions={(options, state) =>
              //   filterCountryOptions(
              //     options,
              //     state,
              //     watch('countries') ?? [],
              //     watch('disallowedCountries') ?? []
              //   )
              // }
              // groupBy={groupCountriesByType}
              defaultValue={watch('categories') ?? []}
              isOptionEqualToValue={(option, value) => {
                return option?.value == value?.value;
              }}
              options={categories}
              renderInput={params => (
                <TextField
                  {...params}
                  // helperText={
                  //   <FormattedMessage
                  //     id={'select_the_countries_informational'}
                  //   />
                  // }
                  label={intl.formatMessage({ id: 'categories' })}
                />
              )}
              {...register('categories')}
              onChange={(event, newValue) => {
                setValue('categories', newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="defaultCurrency"
              filterOptions={(options, state) =>
                filterCurrenciesOptions(options, state)
              }
              getOptionLabel={o => `${o?.name}  ${o?.sign} (${o?.code})`}
              defaultValue={watch('defaultCurrency')}
              isOptionEqualToValue={(option, value) => {
                return option?.oid == value?.oid;
              }}
              options={currencies}
              renderInput={params => (
                <TextField
                  {...params}
                  helperText={
                    <FormattedMessage id={'campaign_default_currency_helper'} />
                  }
                  label={intl.formatMessage({
                    id: 'campaign_default_currency'
                  })}
                />
              )}
              {...register('defaultCurrency')}
              onChange={(event, newValue) => {
                setValue('defaultCurrency', newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id="countries"
              filterOptions={(options, state) =>
                filterCountryOptions(
                  options,
                  state,
                  watch('countries') ?? [],
                  watch('disallowedCountries') ?? []
                )
              }
              groupBy={groupCountriesByType}
              defaultValue={watch('countries')}
              options={countries}
              renderOption={(props, option) => (
                <li {...props}>
                  <FlagIcon countryCode={option?.code} />
                  <Box component={'span'} sx={{ ml: 1 }}>
                    {option?.name}
                  </Box>
                </li>
              )}
              getOptionLabel={option => option?.name}
              renderInput={params => (
                <TextField
                  {...params}
                  helperText={
                    <FormattedMessage
                      id={'select_the_countries_informational'}
                    />
                  }
                  label={intl.formatMessage({ id: 'countries' })}
                />
              )}
              {...register('countries')}
              onChange={(event, newValue) => {
                setValue('countries', newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id="disallowedCountries"
              filterOptions={(options, state) =>
                filterCountryOptions(
                  options,
                  state,
                  watch('disallowedCountries') ?? [],
                  watch('countries') ?? []
                )
              }
              groupBy={groupCountriesByType}
              defaultValue={watch('disallowedCountries')}
              options={countries}
              getOptionLabel={option => option?.name}
              renderOption={(props, option) => (
                <li {...props}>
                  <FlagIcon countryCode={option?.code} />
                  <Box component={'span'} sx={{ ml: 1 }}>
                    {option?.name}
                  </Box>
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  helperText={
                    <FormattedMessage
                      id={'select_the_countries_informational'}
                    />
                  }
                  label={intl.formatMessage({ id: 'disallowed_countries' })}
                />
              )}
              {...register('disallowedCountries')}
              onChange={(event, newValue) => {
                setValue('disallowedCountries', newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Controller
                  name="featured"
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={featured} />
                  )}
                />
              }
              label={intl.formatMessage({ id: 'featured' })}
            />
            <FormHelperText>
              {intl.formatMessage({ id: 'featured_helper' })}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Controller
                  name="calculateTax"
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={calculateTax} />
                  )}
                />
              }
              label={intl.formatMessage({ id: 'calculate_tax' })}
            />
            <FormHelperText>
              {intl.formatMessage({ id: 'calculate_tax_helper' })}
            </FormHelperText>
          </Grid>
          {!hideAdvancedTrackingLogSettings && (
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="saveAllPixels"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={saveAllPixelsForm} />
                    )}
                  />
                }
                label={intl.formatMessage({ id: 'save_all_pixels' })}
              />
              <FormHelperText>
                {intl.formatMessage({ id: 'save_all_pixels_helper' })}
              </FormHelperText>
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex' }}>
              <Controller
                name="revisionSwitch"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={revisionSwitch}
                        onChange={(e, value) => {
                          setValue('revisionPercentage', value ? 0 : null);
                          setValue('revisionSwitch', value ? 1 : 0);
                        }}
                      />
                    }
                    label={intl.formatMessage({ id: 'enable_revision' })}
                  />
                )}
              />

              <Controller
                name="revisionPercentage"
                control={control}
                rules={{
                  required: revisionSwitch
                    ? 'Revision Percentage is required'
                    : false,
                  min: { value: 0, message: 'Must be at least 0' },
                  max: { value: 100, message: 'Must be at most 100' }
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={intl.formatMessage({ id: 'revision_percentage' })}
                    type="number"
                    disabled={!revisionSwitch}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ''
                    }
                    inputProps={{ min: 0, max: 100 }}
                    fullWidth
                  />
                )}
              />
            </Box>

            <FormHelperText>
              {intl.formatMessage({ id: 'revision_helper' })}
            </FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="fraud-score">
                <FormattedMessage
                  id="min_revision_fraud_score"
                  defaultMessage="Min Revision Fraud Score"
                />
              </InputLabel>
              <Controller
                name="minRevisionFraudScoreEnum"
                control={control}
                defaultValue={null} // Default is disabled (null)
                render={({ field }) => (
                  <Select
                    label={
                      <FormattedMessage
                        id="min_revision_fraud_score"
                        defaultMessage="Min Revision Fraud Score"
                      />
                    }
                    labelId="fraud-score-label"
                    id="fraud-score"
                    {...field}
                  >
                    <MenuItem value={null}>
                      <FormattedMessage
                        id="disabled"
                        defaultMessage="Disabled"
                      />
                    </MenuItem>
                    {Object.entries(FraudScore).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>
                {intl.formatMessage({ id: 'min_revision_fraud_score_helper' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="fraud-score">
                <FormattedMessage
                  id="min_denied_fraud_score"
                  defaultMessage="Min Denied Fraud Score"
                />
              </InputLabel>
              <Controller
                name="minDeniedFraudScoreEnum"
                control={control}
                defaultValue={null} // Default is disabled (null)
                render={({ field }) => (
                  <Select
                    label={
                      <FormattedMessage
                        id="min_denied_fraud_score"
                        defaultMessage="Min Denied Fraud Score"
                      />
                    }
                    labelId="fraud-score-label"
                    id="fraud-score"
                    {...field}
                  >
                    <MenuItem value={null}>
                      <FormattedMessage
                        id="disabled"
                        defaultMessage="Disabled"
                      />
                    </MenuItem>
                    {Object.entries(FraudScore).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>
                {intl.formatMessage({ id: 'min_denied_fraud_score_helper' })}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary">
          <FormattedMessage id="save" />
        </Button>
      </Paper>
    </form>
  );
}

function CustomDatePickerWrapper({
  name,
  labelKey,
  control,
  setValue,
  error,
  value,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <WithKeyboardDatePicker
            {...props}
            field={field}
            value={value}
            labelKey={labelKey}
            error={!!error} // Check for errors
            helperText={error?.message} // Display error message
            onDatePickerChange={date => setValue(name, date)}
          />
        );
      }}
    />
  );
}

export default CampaignGeneralSettingsForm;
