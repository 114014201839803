import { useMemo, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  Popover,
  Divider,
  Tooltip,
  Typography,
  Button
} from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  deleteUserMenuShortcuts,
  getUserMenuShortcuts
} from 'src/actions/userActions';
import NewUserCustomMenuShortcutModal from 'src/modals/NewUserCustomMenuShortcutModal';
import { displayUpdateSnackbar } from '../../../../../utils/utils';
import { useDialog } from 'src/providers/DialogProvider';
import { UserPermissionsEnum } from 'src/utils/enums/UserPermissionsEnum';
import Iconify from 'src/components/iconify';
import SearchBar from 'src/components/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { accountSelector } from 'src/selectors/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { activeStyle } from '../../../../../layouts/DashboardLayout/SubBar/index';
import { DEFAULT_SHORCUT_ICON } from '../../../../../utils/staticObjects';

function UserCustomMenuShortcuts({ setParentAnchorEl = () => {} }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [currentShortcut, setCurrentShortcut] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const history = useHistory();
  const showDialog = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();

  const userCustomShortcuts = useSelector(
    state => accountSelector(state)?.shortcuts
  );

  const isCurrentPath = targetPath => {
    return `${location.pathname}${location.hash}` === targetPath;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      await getUserMenuShortcuts(dispatch);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setCurrentShortcut(null);
    setIsOpen(true);
  };

  const handleEditOpen = shortcut => {
    setCurrentShortcut(shortcut);
    setIsOpen(true);
    handleMoreActionsClose();
  };

  const handleDeleteRequest = shortcut => {
    deleteUserMenuShortcuts(shortcut.id)
      .then(async () => {
        displayUpdateSnackbar(enqueueSnackbar, intl, true);
        handleMoreActionsClose();
        await fetchData();
      })
      .catch(() => {
        displayUpdateSnackbar(enqueueSnackbar, intl, false);
        handleMoreActionsClose();
      });
  };

  const handleDelete = shortcut => {
    showDialog({
      title: <FormattedMessage id="are_you_sure" />,
      showCancel: true,
      customApproveText: 'ok',
      message: <FormattedMessage id="are_you_sure_you_want_to_delete" />
    }).then(r => r && handleDeleteRequest(shortcut));
  };

  const handleMoreActionsOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMoreActionsClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleQueryChange = e => {
    setSearchQuery(e.target.value);
  };

  const handleNavigate = url => {
    if (url?.startsWith('/app')) {
      history.push(url);
    } else {
      window.open(url, '_blank');
    }
    setParentAnchorEl(null);
  };

  const filteredShortcuts = useMemo(
    () =>
      userCustomShortcuts?.filter(shortcut =>
        shortcut.title.toLowerCase().includes(searchQuery.toLowerCase())
      ) || [],
    [searchQuery, userCustomShortcuts]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <Button onClick={handleOpen} color="primary" sx={{ fontSize: '12px' }}>
          <FormattedMessage id={'add_shortcut'} />
        </Button>
      </Box>

      <SearchBar
        sx={{
          px: 1,
          my: 1,
          '& .MuiInputBase-input': {
            py: 0.5
          }
        }}
        query={searchQuery}
        handleQueryChange={handleQueryChange}
        placeholder="Search"
      />

      <Divider />

      <List
        sx={{
          width: '400px',
          bgcolor: 'background.paper',
          maxHeight: '300px',
          overflow: 'auto'
        }}
      >
        {loading ? (
          <LoadingScreen />
        ) : filteredShortcuts.length === 0 ? (
          <ListItem>
            <ListItemText
              sx={{ textAlign: 'center' }}
              primary={intl.formatMessage({ id: 'shortcuts_helper' })}
            />
          </ListItem>
        ) : (
          filteredShortcuts.map(shortcut => (
            <ListItem
              key={shortcut.id}
              disablePadding
              sx={isCurrentPath(shortcut.url) ? activeStyle : {}}
              secondaryAction={
                <Tooltip title={intl.formatMessage({ id: 'more_options' })}>
                  <IconButton
                    edge="end"
                    onClick={event => handleMoreActionsOpen(event, shortcut)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemButton
                component="a"
                href={shortcut.url}
                onClick={e => {
                  e.preventDefault();
                  handleNavigate(shortcut.url);
                }}
              >
                <ListItemIcon>
                  <Iconify
                    sx={{
                      color: theme => theme.palette.text.primary
                    }}
                    icon={shortcut.icon ?? DEFAULT_SHORCUT_ICON}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    maxWidth: '80%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    variant: 'body2'
                  }}
                  title={shortcut.title}
                  primary={shortcut.title}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMoreActionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          {selectedRow && (
            <>
              <ListItem
                disablePadding
                onClick={() => handleEditOpen(selectedRow)}
              >
                <ListItemButton>
                  <ListItemText primary={intl.formatMessage({ id: 'edit' })} />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={() => handleDelete(selectedRow)}
              >
                <ListItemButton>
                  <ListItemText
                    primary={intl.formatMessage({ id: 'delete' })}
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Popover>

      <NewUserCustomMenuShortcutModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fetchData={fetchData}
        shortcutToEdit={currentShortcut}
      />
    </Box>
  );
}

export default UserCustomMenuShortcuts;
