import * as React from 'react';
import Stack from '@mui/material/Stack';
import Page from 'src/components/Page';

export default function SideBySideView({ hideFooterForce = false, children }) {
  return (
    <Page hideFooterForce={hideFooterForce}>
      <Stack
        direction="column"
        component="main"
        sx={[
          {
            justifyContent: 'center',
            height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
            marginTop: 'max(40px - var(--template-frame-height, 0px), 0px)',
            minHeight: '30%'
          },
          theme => ({
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              minHeight: '100%',
              minWidth: '100%',
              zIndex: 0,
              inset: 0,
              backgroundImage: `radial-gradient(at 65% 50%, ${theme.palette.background.paper} 20%, ${theme.palette.background.dark} 70%) !important`,
              backgroundRepeat: 'no-repeat'
            }
          })
        ]}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            p: 2,
            mx: 'auto'
          }}
        >
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{
              justifyContent: 'center',
              gap: { xs: 6, sm: 12 },
              p: { xs: 0, sm: 4 },
              m: 'auto',
              zIndex: 1
            }}
          >
            {children}
          </Stack>
        </Stack>
      </Stack>
    </Page>
  );
}
