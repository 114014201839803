export default {
  sign_up: 'הרשמה',
  create_x_account: 'צור חשבון',
  have_an_account: 'חשבון קיים?',
  back_to_home: 'חזרה',
  back: 'חזרה',
  terms: 'תנאים',
  disagree: 'לא מסכים',
  i_agree: 'מסכים',
  i_have_read_the: 'קראתי ואני מסכימ/ה',
  terms_and_conditions: 'תנאי שימוש',
  create_account: 'צור חשבון',
  general_error: 'בעיה כללית',
  sign_in: 'כניסה',
  sign_in_on_the_internal_platform: 'כניסה למערכת השותפים',
  create_new_account: 'צור חשבון',
  forgot_password: 'שכחתי סיסמה',
  advertisers: 'מפרסמים',
  pending_approvals: 'ממתינים לאישור',
  advertiser: 'מפרסם',
  home: 'בית',
  test: 'בדיקה',
  test_conversion: 'המרת טסט',
  disallowed_countries: 'מדינות אסורות',
  everything_in: 'כל היתרונות של תוכנית {previousPlan} ובנוסף:',
  includes: 'מה כלול בחבילה:',
  join_requests: 'בקשות הצטרפות',
  dashboard: 'דשבורד',
  compare_plans: 'השוואת תוכניות',
  management: 'ניהול',
  import: 'יבוא',
  all_advertisers: 'כל המפרסמים',
  coupons: 'ניהול קופונים',
  add_coupon: 'הוסף קופון',
  new_advertiser: 'מפרסם חדש',
  add: 'הוסף',
  add_advertiser: 'הוסף מפרסם',
  settings_updated_successfully: 'עודכן בהצלחה',
  save_changes: 'שמור שינויים',
  save: 'שמור',
  change_email: 'שינוי מייל',
  email_address: 'מייל',
  confirm_email_address: 'חזור על המייל',
  last_name: 'שם משפחה',
  first_name: 'שם פרטי',
  profile: 'פרופיל',
  change_password: 'שינוי סיסמה',
  password_confirmation: 'חזור על הסיסמה',
  password: 'סיסמה',
  min_pass_length_message:
    'You have entered an invalid password. Please use 10-20 characters.',
  password_required: 'שדה חובה',
  enter_valid_email: 'כתובת דוא"ל זו אינה חוקית',
  email_required: 'שדה חובה',
  invalid_password_message: 'סיסמה לא חוקית',
  invalid_email_message: 'אימייל לא חוקי',
  log_in: 'כניסה',
  first_name_validation:
    'First name can contain only letters (a-z), numbers (0-9), and periods (.) [with characters after] and 2-20 characters long',
  last_name_validation:
    'Last name can contain only letters (a-z), numbers (0-9), and periods (.) [with characters after] and up to 20 characters long',
  submit: 'שליחה',
  emails_must_match: 'Both emails must be the same',
  campaign: 'קמפיין/הצעה',
  campaigns: 'קמפיינים/הצעות',
  all_campaigns: 'כל הקמפיינים',
  new_campaign: 'קמפיין חדש',
  add_campaign: 'הוספת קמפיין',
  all: 'הכל',
  title: 'כותרת',
  description: 'תיאור',
  status: 'סטטוס',
  fileStatus: 'סטטוס קובץ',
  privacy: 'פרטיות',
  active: 'פעיל',
  disable: 'הפוך ללא פעיל',
  disabled: 'לא פעיל',
  paused: 'מושהה',
  public: 'פומבי',
  pre_moderation: 'אישור מראש',
  private: 'פרטי',
  no_results_found: 'לא נמצאו תוצאות',
  this_field_is_required: 'שדה חובה',
  actions: 'פעולות',
  general: 'כללי',
  tracking: 'מעקב',
  traffic_source: 'מקור תנועה',
  traffic_sources: 'מקורות תנועה',
  currency: 'מטבע',
  features: 'מאפיינים',
  start_date: 'תאריך התחלה',
  end_date: 'תאריך סיום',
  hold_time: 'זמן התמנה',
  successfully_updated: 'עודכן בהצלחה',
  successfully_created: 'נוצר בהצלחה',
  last_update_newest_first: 'Last update (newest first)',
  last_update_oldest_first: 'Last update (oldest first)',
  total_orders_high_to_low: 'Total orders (high to low)',
  total_orders_low_to_high: 'Total orders (low to high)',
  delete: 'מחיקה',
  edit: 'עריכה',
  name: 'שם',
  website: 'אתר',
  contact_person: 'איש קשר',
  im_or_skype: 'Im / Skype',
  full_name: 'שם מלא',
  address1: 'כתובת 1',
  address2: 'כתובת 2',
  allowed_ips: 'IP מותר',
  note: 'הערה',
  secure_postback_code: 'Secure Post Back Code',
  vat_code: 'חפ',
  zipcode: 'מיקוד',
  true: 'True',
  false: 'False',
  update_advertiser: 'עדכן מפרסם',
  create_advertiser: 'צור מפרסם',
  edit_advertiser: 'ערוך מפרסם',
  update_campaign: 'עדכון קמפיין',
  create_campaign: 'יצירת קמפיין',
  settings: 'הגדרות',
  create_traffic_source: 'צור מקור תנועה',
  update_traffic_source: 'עדכן מקור תנועה',
  edit_traffic_source: 'ערוך מקור תנועה',
  add_traffic_source: 'הוסף מקור תנועה',
  create: 'צור',
  update: 'עדכן',
  traffic_source_created: 'מקור תנועה נוצר',
  new_company_traffic_source: 'New Company Traffic Source',
  new_traffic_source: 'New Traffic Source',
  edit_campaign: 'ערוך קמפיין',
  tracking_url: 'קישור מעקב',
  preview_url: 'צפייה מוקדמת',
  redirect_type: 'Redirect Type',
  traffic_back_url: 'Traffic Back Url',
  no_advertisers_found: 'No Advertisers Found',
  no_campaign_create_advertiser:
    'In order to create a campaign, Please create an advertiser first',
  create_traffic_source_first:
    'In order to create a campaign you need a traffic source first',
  discounted_prices: 'Discounted Prices',
  email_verified: 'כתובת אימייל אומתה',
  unique_ip_only: 'Unique IP is Required',
  one_of_ips_invalid:
    'Invalid IP Address Format: Please enter a valid IP address in the correct format (e.g., 192.168.1.1). Ensure each section is a number between 0 and 255 and separated by periods.',
  s2s_postback: 'S2S (Postback)',
  probabilistic_attribution: 'Probabilistic Attribution',
  image: 'Image',
  js: 'JavaScript',
  iframe: 'Iframe',
  next: 'הבא',
  ip_is_invalid: 'IP is invalid',
  payout: 'תשלום',
  payouts: 'תשלומים',
  revenue: 'רווח',
  goal_value: 'Goal Value',
  goal_title: 'Goal Title',
  payment_type: 'סוג תשלום',
  countries: 'מדינות',
  invalid: 'Invalid',
  general_settings_title: 'General Setting Title',
  general_settings_start_date: 'General Setting Start Date',
  general_settings_end_date: 'General Setting End Date',
  general_settings_hold_time: 'General Setting Hold Time',
  tracking_settings_integration_s2s_postback:
    'Tracking Settings Integration S2s Postback',
  tracking_settings_traffic_back_url: 'Tracking Settings Traffic Back Url',
  tracking_settings_preview_url: 'Tracking Settings Preview Url',
  tracking_settings_tracking_url: 'Tracking Settings Tracking Url',
  payouts_settings_goal_title: 'Payouts Settings Goal Title',
  payouts_settings_goal_value: 'Payouts Settings Goal Value',
  payouts_settings_revenue: 'Payouts Settings Revenue',
  payouts_settings_payouts: 'Payouts Settings',
  field: 'Field',
  ip_should_be_unique: 'IP Should Be Unique',
  required: 'חובה',
  field_invalid: 'שדה לא תקין',
  general_payout: 'General Payout',
  personal_payout: 'Personal Payout',
  valid: 'תקין',
  resource_name_unavailable: 'Resources name unavailable',
  cancel: 'ביטול',
  end_date_is_invalid: 'End date is invalid',
  affiliates: 'שותפים',
  affiliate: 'שותף',
  click_session_life_span: 'Click Session Life Span',
  security: 'אבטחה',
  password_confirm: 'אשר',
  current_password: 'סיסמא נוכחית',
  name_unavailable: 'Name Unavailable',
  statistics: 'דוחות',
  payouts_soon: 'Payouts (Soon)',
  conversions_soon: 'Conversions (Soon)',
  affiliates_soon: 'Affiliates (Soon)',
  campaign_stats: 'דוחות סיכום',
  campaign_id: 'קמפיין',
  campaign_name: 'שם הקמפיין',
  hosts: 'דומיינים',
  hits: 'הקלקות',
  approved: 'מאושר',
  disapproved: 'לא אושר',
  pending: 'ממתין',
  declined: 'נדחה',
  decline: 'דחה',
  cr: 'CR',
  epc: 'EPC',
  traffic: 'תנועה',
  conversions: 'המרות',
  finances: 'פיננסי',
  filters: 'סינון',
  hold: 'המתנה',
  total: 'הכל',
  qty: 'QTY',
  null: 'NULL',
  trafficback: 'Traffic Back',
  earnings: 'רווחים כוללים',
  ecpm: 'ECPM',
  not_selected: 'הכל',
  filter: 'סינון',
  conversion_id: 'מספר המרה',
  comment: 'הערה',
  sub_id: 'Sub ID',
  sub_id_text: 'Sub ID Text',
  sub_1: 'sub1',
  sub_2: 'sub2',
  sub_3: 'sub3',
  sub_4: 'sub4',
  sub_5: 'sub5',
  sub_6: 'sub6',
  sub_7: 'sub7',
  sub_8: 'sub8',
  sub1: 'Sub 1',
  sub2: 'Sub 2',
  sub3: 'Sub 3',
  sub4: 'Sub 4',
  sub5: 'Sub 5',
  sub6: 'Sub 6',
  sub7: 'Sub 7',
  sub8: 'Sub 8',
  goal_name: 'קטגוריה',
  income: 'Income',
  device: 'Device',
  geography_ip: 'Geography / IP',
  amount: 'מחיר',
  date: 'תאריך',
  today: 'יום',
  last_week: 'שבוע',
  last_two_weeks: 'שבועיים',
  last_month: 'חודש',
  last_three_months: 'שלושה חודשים',
  last_six_months: 'חצי שנה',
  last_year: 'שנה',
  time_period: 'תקופת זמן',
  clear_filters: 'ניקוי',
  custom: 'מותאם אישית',
  conversion_stats: 'נתוני המרה',
  create_conversion: 'הוסף המרה',
  update_conversion: 'עדכן המרה',
  edit_conversion: 'ערוך המרה',
  all_conversions: 'כל ההמרות',
  add_conversion: 'הוסף המרה',
  conversion_created: 'Conversion Created',
  conversion_updated: 'Conversion Updated',
  currently_unavailable: 'Currently Unavailable',
  creation_date: 'תאריך יצירה',
  all_payouts: 'כל התשלומים',
  add_payout: 'הוסף תשלום',
  create_payout: 'צור תשלום',
  denied: 'נדחה',
  number: 'מספר',
  created_at: 'נוצר',
  approved_and_paid: 'מאושר ושולם',
  order_id: 'מספר הזמנה',
  include_affiliate: 'כולל שותף',
  amount_to_be_approved: 'מחיר לאישור',
  commission_to_be_approved: 'עמלה לאישור',
  edit_payout: 'ערוך תשלום',
  advertiser_name: 'שם מפרסם',
  total_amount: 'מכירות',
  campaign_start_date: 'Campaign Start Date',
  campaign_end_date: 'Campaign End Date',
  update_payout: 'עדכן תשלום',
  total_commission: 'עמלה כוללת',
  download_report: 'Download Report',
  file_uploaded_successfully: 'file uploaded successfully',
  download_latest_report: 'הורד את הקובץ האחרון שהועלה',
  download_original_report: 'Download Original Report',
  payout_updated: 'Payout Updated',
  include_commission: 'כולל עמלה',
  no_report_attached: 'אין דוח מצורף',
  report_is_valid: 'Report Is Valid',
  view_payout: 'View Payout',
  affiliates_requests: 'בקשות שותף',
  requests: 'בקשות',
  approve: 'אישור',
  approve_affiliate: 'אשר שותף',
  reject_affiliate: 'דחה שותף',
  affiliate_payouts: 'תשלומי שותף',
  save_and_next: 'שמור והמשך',
  available_to_payout: 'Available To Payout',
  request_payout: 'בקשת תשלום',
  commission: 'עמלה',
  affiliate_commission: 'עמלת שותף',
  see_all: 'הכל',
  clicks: 'הקלקות',
  more_details: 'פרטים נוספים',
  email: 'מייל',
  emails: 'מיילים',
  affiliate_website: 'אתר',
  disapprove: 'דחייה',
  change_to_approved: 'שנה למאושר',
  change_to_declined: 'שנה לנדחה',
  contact_details: 'יצירת קשר',
  login_as_affiliate: 'כניסה כשותף',
  login: 'כניסה',
  add_affiliate: 'הוספת שותף',
  create_affiliate: 'יצרת שותף',
  edit_affiliate: 'ערוך שותף',
  try_again_later: 'נסה מאוחר יותר',
  added_affiliate: 'שותף נוצר',
  affiliate_management: 'ניהול שותפים',
  merchant_commission: 'עמלת מפרסם',
  purchase_date: 'תאריך רכישה',
  phone_number: 'מס נייד',
  affiliate_updated: 'עדכון שותף',
  affiliate_created: 'שותף נוצר',
  merchant_management: 'ניהול מפרסם',
  user_management: 'ניהול משתמשים',
  identity: 'identity',
  smtp: 'SMTP',
  edit_merchant: 'ערוך מפרסם',
  merchant_updated: 'מפרסם עודכן',
  add_merchant: 'הוסף מנהל',
  create_merchant: 'יצירת מפרסם',
  company_name: 'שם החברה',
  company_name_for_invoice: 'שם החברה',
  update_merchant: 'עדכון מפרסם',
  campaign_logo: 'לוגו קמפיין',
  io_document: 'חשבונית',
  select_logo: 'בחר לוגו',
  select_io_document: 'חוזה',
  img: 'תמונה',
  picture_unavailable: 'Picture Unavailable',
  logo: 'לוגו',
  id: 'מזהה',
  select_campaign: 'בחר קמפיין',
  js_landing_page: 'Javascript Landing Page',
  js_thank_you: 'Js Thank You',
  js_landing_page_text:
    'על המפרסם להוסיף את הקוד אחרי תגית ה<body> בדף הנחיתה נדרש להוסיף פרמטר &click_id={click_id} לURL המעקב',
  js_thank_you_text:
    'על המפרסם להוסיף את הקוד אחרי תגית ה<body> בדף "Thank you"',
  end_date_before_start_date: 'End date is before start date',
  login_email: 'Login Email',
  login_password: 'Login Password',
  server: 'Server',
  port: 'Port',
  protocol: 'Protocol',
  smtp_and_ssl: 'SMTP + SSL',
  smtp_and_tls: 'SMTP + TLS',
  created: 'נוצר',
  updated: 'עודכן',
  forgot_password_message:
    'כדי לאפס את סיסמתך יש למלא את כתובת הדואר האלקטרוני איתה נרשמת לשירות ולפעול לפי ההוראות שנשלח לך במייל.',
  featured: 'מקודם',
  search_affiliate: 'חיפוש שותף',
  search_advertisers: 'חיפוש מפרסם',
  search_campaigns: 'חיפוש קמפיין',
  search_payouts: 'חיפוש תשלומים',
  search_conversions: 'חיפוש המרות',
  search_campaign_request: 'חיפוש בקשות קמפיין',
  branding: 'מיתוג',
  search_traffic_source: 'חפש מקור תנועה',
  performance_chart: 'ביצועים',
  last_conversions: 'המרות אחרונות',
  top_campaigns: 'קמפיינים מובילים',
  last_30_days: 'חודש אחרון',
  reports: 'דוחות',
  privacy_policy: 'פרטיות',
  documents: 'מסמכים',
  search: 'חיפוש',
  SUB1: 'SUB1',
  SUB2: 'SUB2',
  SUB3: 'SUB3',
  SUB4: 'SUB4',
  SUB5: 'SUB5',
  SUB6: 'SUB6',
  SUB7: 'SUB7',
  SUB8: 'SUB8',
  Paused: 'מושהה',
  Disabled: 'לא פעיל',
  Active: 'פעיל',
  logout: 'יציאה',
  there_is_not_an_available_commission: 'אין עמלה זמינה למשיכה',
  statuses: 'סטטוסים',
  enter_new_password: 'הכנס סיסמא חדשה',
  download: 'הורד',
  export: 'ייצוא',
  not_active: 'לא פעיל',
  activate: 'הפעל',
  test_email: 'שלח בדיקת אימייל',
  alias: 'כינוי',
  smtp_connection_failed_change_details:
    'חיבור smtp נכשל, תשקול לשנות את אחד השדות',
  mail: 'מייל',
  header: 'Header',
  footer: 'Footer',
  attribution: 'שיוך',
  first_click: 'קליק ראשון',
  last_click: 'קליק אחרון',
  coupon_code: 'קוד קופון',
  earn_per_click: 'Earn Per Click',
  click_rate: 'Click Rate',
  campaign_details: 'פירוט קמפיין',
  disallowed: 'תנועה אסורה',
  hint: 'רמז',
  coupon: 'קופון',
  cname_missing_add_to_dns_manager: 'חסר cname, בדוק במנהל הDNS',
  new_affiliate_needs_activation: 'שותף חדש צריך לאשר מייל הצטרפות',
  new_affiliate_needs_manual_activation: 'שותף חדש צריך אישור מנהל',
  change_manager: 'החלף מנהל',
  manager: 'מנהל',
  default_merchant: 'מנהל ברירת מחדל',
  merchant: 'מנהל',
  need_help: 'צריך עזרה?',
  check_our_docs: 'בדקו את הדוקומנטציה שלנו',
  documentation: 'דוקומנטציה',
  activation: 'הפעלת חשבון',
  changed_email: 'שינוי אימייל',
  reset_password: 'איפוס סיסמא',
  invitation: 'הזמנה',
  password_changed: 'שינוי סיסמא',
  enter_email: 'הכנס אימייל',
  and: 'ו',
  welcome: 'מייל הפעלה',
  available_macros: 'מאקרוס אפשריים',
  custom_fields: 'שדות בהתאמה אישית',
  add_custom_field: 'הוסף שדה בהתאמה אישית',
  search_custom_field: 'חפש שדה מותאם',
  edit_custom_field: 'ערוך שדה מותאם',
  create_custom_field: 'צור שדה מותאם',
  custom_field_name: 'שם השדה המותאם',
  update_custom_field: 'עדכן שדה מותאם',
  add_custom_doc: 'הוסף מסמך בהתאמה אישית',
  search_custom_doc: 'חפש מסמך מותאם',
  edit_custom_doc: 'ערוך מסמך מותאם',
  create_custom_doc: 'צור מסמך מותאם',
  update_custom_doc: 'עדכן מסמך מותאם',
  not_required: 'לא חובה',
  field_name_is_already_used: 'כבר יש שדה בשם הזה',
  are_you_sure: 'האם אתה בטוח?',
  text_area: 'Text Area',
  check_box: 'Check Box',
  input: 'Input',
  type: 'סוג',
  default_manager: 'מנהל ברירת מחדל',
  brand_logo: 'לוגו המותג',
  billing: 'חבילות ותשלומים',
  payments: 'תשלומים',
  plan: 'תוכנית',
  paypal: 'פייפאל',
  wire_transfer: 'העברה בנקאית',
  payout_source: 'סוג תשלום',
  price: 'מחיר',
  transaction_id: 'מזהה עסקה',
  view_invoice: 'לחשבונית',
  upgrade: 'שדרג',
  reached_max_campaigns_limit: 'הגעת למכסת הקמפיינים שלך',
  work_email: 'אימייל עבודה',
  message: 'הודעה',
  contact_us: 'צור קשר',
  we_will_contact_u_soon: 'ניצור איתך קשר בקרוב!',
  terms_and_conditions_and_privacy_policy: 'תנאי השימוש ומדיניות פרטיות',
  current_email: 'אימייל נוכחי',
  please_update_your_plan: 'בבקשה עדכן את המנוי שלך',
  here: 'כאן',
  custom_domain_updated: 'דומיין מותאם אישית עודכן',
  your_email: 'המייל שלך',
  allowed: 'מורשה',
  created_merchant: 'מנהל נוצר',
  affiliate_company: 'חברת המשווק',
  linked_to_payout: 'מקושר לתשלום',
  subject: 'נושא הפנייה',
  has_payout: 'מקושר לתשלום',
  yes: 'כן',
  no: 'לא',
  industry: 'תעשייה',
  select: 'Select',
  view_conversions: 'ראה עסקאות',
  search_conversions_by_campaign_affiliate_or_payout:
    'חפש עסקאות לפי שם הקמפיין או שם המשווק.',
  traffic_back_explanation:
    'הלוקח יועבר ל "Traffic Back Url" במקרה שהקמפיין בוטל או שהייתה שגיאה',
  goal_value_explanation_row_1:
    'שדה Goal Value מאפשר אופציית תשלום לפי קטגוריה.',
  goal_value_explanation_row_2: 'השדה הזה חייב לחזור בקוד / postback שלך',
  goal_value_explanation_row_3: `למידע נוסף <a href="https://help.tracknow.io/knowledge-base/create-goals/">לחץ כאן</a>`,

  test_smtp_email: 'שלח אימייל בדיקה',
  campaign_terms: 'תנאי הקמפיין',
  new_email: 'אימייל חדש',
  payment_details: 'פרטי תשלום',
  request_date: 'תאריך הבקשה',
  reason: 'סיבה',
  affiliate_request: 'בקשת המשווק',
  the_reason_will_be_visible_to_the_affiliate: 'הסיבה תהיה נגישה למשווק',
  affiliate_registration_url: 'קישור הרשמה לשותפים',
  copy: 'העתק',
  copied_to_clipboard: 'הועתק',
  minimum_withdrawal_amount: 'סכום מינימלי למשיכה',
  payment_methods: 'צורת תשלום',
  add_payment_method: 'הוסף צורת תשלום',
  search_payment_method: 'חפש צורת תשלום',
  create_payment_method: 'צור צורת תשלום',
  edit_payment_method: 'ערוך צורת תשלום',
  payment_method_created: 'צורת תשלום נוצרה',
  payment_method: 'צורת תשלום',
  conversion_statuses_explanation:
    'כדי ליצור תשלום באקסל ולשנות סטטוסים רבים בבת אחת, בחר מפרסם אחד ותאריכים ובחר: צור תשלום',
  powered_by_tracknow: 'מופעל על ידי Tracknow',
  change_email_confirmation_email_sent: 'אימייל אימות נשלח אליך',
  check_your_data: 'תבדוק את הנתונים',
  are_you_sure_you_want_to_create_a_payout:
    'האם אתה בטוח שאתה רוצה ליצור תשלום',
  create_payout_confirmation: 'וידוא יצירת תשלום',
  payout_statuses_explanation:
    'מצבי תשלום: APPROVED - תשלום זמין לתשלום. PENDING - תהליך התשלום עדיין לא הסתיים. DENIED - התשלום נדחה מסיבה מסוימת.',
  payout_created: 'תשלום נוצר',
  excel_statuses_explanation:
    'סטטוסים של אקסל: שנה את הסטטוסים בתוך קובץ האקסל והעלה את האקסל לשרת. \n\n' +
    ' אנא השתמש בסטטוסים הבאים בתוך האקסל:\n' +
    ' APPROVED - העמלה תהיה זמינה עבור השותף. \n' +
    ' PENDING - ההמרה תישאר במצב ממתין. \n' +
    ' DENIED - ההמרה תוסר מהיתרה ממתינה/יתרה זמינה.\n' +
    '\n' +
    '\n' +
    'שים לב שסטטוס התשלום אינו משפיע על סטטוסי ההמרה, אם תרצה לשנות את סטטוסי ההמרה, צור תשלום חדש ממסך ההמרה.\n' +
    '\n' +
    "* שים לב שסטטוסי ההמרה ישתנו רק כאשר סטטוס התשלום ישתנה ל'APPROVED' ו-Excel יועלה.\n" +
    '\n' +
    "* כדי לשנות את סטטוסי ההמרה, אנא העלה את קובץ האקסל.'",
  system_id: 'מזהה',
  tax: 'מעמ',
  ready: 'מוכן',
  number_must_be_between: 'המספר חייב להיות בין {min} ל {max}',
  edit_statuses: 'ערוך סטטוסים',
  apply_changes_to: 'השם שינויים ל({count})',
  failed: 'נכשל',
  processing: 'מעבד',
  affiliate_payout: 'המרות שותפים',
  permissions: 'הרשאות',
  enter_new_password_reset: 'הזן את הסיסמה החדשה למטה כדי לאפס את הסיסמה.',
  referer: 'מפנה',
  user_agent: 'User Agent',
  country: 'מדינה',
  send_test_mail: 'אימייל בדיקה נשלח בהצלחה',
  please_enable_smtp_first: 'אנא אשר את SMTP ',
  request_details: 'פרטי הבקשה',
  scale: 'קנה מידה',
  rotate: 'סיבוב',
  crop: 'חתוך',
  time_format: 'פורמט זמן',
  time_zone: 'איזור זמן',
  billing_description:
    "Discover the Tracknow affiliate platform's billing page. Begin your affiliate marketing journey for free with our Free plan. Upgrade to our Team or Enterprise plans as you grow, unlocking advanced features. Flexible payment options available. Join us today and unleash the power of Tracknow!",
  personal_settings: 'הגדרות פרופיל',
  company_settings: 'הגדרות חברה',
  preview: 'תצוגה מקדימה',
  day: 'יום',
  year: 'שנה',
  month: 'חודש',
  group_by: 'קבץ לפי',
  deep_link: 'קישור',
  tracking_params: 'פרמטר מעקב',
  yesterday: 'אתמול',
  order_amount: 'מחיר הזמנה',
  unlimited_sales: 'ללא הגבלת מכירות',
  up_to_x_affiliates: 'עד {0} שותפים פעילים',
  ssl_setup: 'הגדרת SSL',
  custom_domain: 'כתובת אתר לבחירה',
  dedicated_smtp: 'הגדרת SMTP',
  custom_commissions: 'עמלות מותאמות לפי קטגוריה',
  custom_commission_affiliate: 'עמלות מותאמות לשותף',
  email_support: 'תמיכה במייל',
  no_limit_campaigns: 'ללא הגבלת קמפיינים',
  no_limit_affiliates: 'ללא הגבלת שותפים',
  email_and_chat_support: 'תמיכה במייל ובצאט',
  dedicated_account_manager: 'מנהל תיק אישי',
  coupon_offline: 'מעקב אחר קופון אופליין',
  invoices: 'חשבוניות',
  next_subscription_fee_due: 'מחזור חיוב הבא:',
  you_wiil_return_to_the_free_tier_at: 'אתה תחזור לתוכנית החינמית ב:',
  are_you_sure_you_want_to_reset_cname: 'אתה בטוח שברצונך לאפס את הדומיין?',
  reset_cname: 'לאיפוס דומיין',
  subscription_details: 'פרטי מנוי:',
  max_x_capmaigns: 'עד {0} קמפיינים',
  unlimitid_campaigns: 'ללא הגבלת קמפיינים',
  tax_not_included: 'לא כולל מע"מ',
  email_for_invoice: 'אימייל לקבלת חשבונית:',
  plans_and_pricing: 'מחירים',
  reset: 'אפס',
  your_membership_tier: 'מנוי:',
  click_here: 'לחץ כאן',
  advertiser_should_add_the:
    'על המפרסם להוסיף את הפרמטרים הבאים לURL : order_id, amount, goal, לעוד מידע בבקשה',
  for_a_tutorial_on_how_to_add_postback: 'למדריך על הוספת postback בבקשה',
  payout_settings: 'הגדרות תשלום',
  brand_colors: 'צבעי המותג',
  custom_script: 'סקריפט מותאם אישית',
  getting_started: 'התחלה מהירה',
  done: 'סיים',
  deleted_successfully: 'נמחק בהצלחה',
  creatives: 'קראייטיב',
  add_new: 'הוסף חדש',
  create_a_campaign: 'יצירת קמפיין',
  adding_an_affiliate: 'הוספת שותף',
  affiliate_dashboard: 'דשבורד שותפים',
  feel_free_to_contact_us: 'לעזרה בנושא הטמעת המערכת צרו איתנו קשר',
  campaign_management: 'ניהול קמפיין',
  add_new_crative: 'הוספת קראייטיב חדש',
  are_you_sure_you_want_to_delete_this_creative: 'מחיקה',
  are_you_sure_you_want_to_delete: 'אתה בטוח שברצונך למחוק?',
  delete_notify: 'אתה בטוח שברצונך למחוק את הקראייטיב?',
  creative: 'קריאייטיב',
  mlm_commission: 'עמלת מפנה',
  mlm_level2_commission: 'עמלת מפנה 2',
  allow_mlm: 'אפשר MLM (Multi Level Marketing)',
  mlm: 'שיווק רשתי (MLM)',
  mlm_percent: 'אחוז עמלת MLM',
  allow_mlm_level2: 'אפשר MLM מדרגה 2',
  mlm_level2_percent: 'אחוז עמלת MLM דרגה 2',
  referrer: 'Referrer',
  default_campaign: 'קמפיין דיפולטיבי',
  conversion_tracking_setup: 'הטמעת פיקסל באתר',
  content: 'תוכן',
  inactive: 'לא פעיל',
  draft: 'טיוטה',
  none: 'ללא',
  invalid_url: 'URL לא תקין',
  categories_tracking: 'עמלות מותאמות לפי קטגוריה',
  phone_support: 'תמיכה טלפונית',
  chat_support: 'תמיכה בצאט',
  unlimited_banners: 'באנרים ללא הגבלה',
  impressions: 'חשיפות (CPM)',
  cpm_commissions: 'עמלת CPM',
  conversion_commissions: 'עמלת המרות',
  ppc_commissions: 'עמלת PPC',
  support_app_conversions: 'אפשר תמיכת מעקב באפליקציות (IOS/Android)',
  support_app_helper: '* למקרה והקליק והרכישה מגיעים מאיג`נטים שונים',
  requested_commission: 'עמלה מבוקשת',
  manage: 'נהל',
  select_a_payout: 'בחר תשלום',
  select_a_campaign: 'בחר קמפיין',
  use_general_capmaign_settings: 'השתמש בהגדרות כלליות',
  add_user_to_campaign: 'צרף שותף לקמפיין',
  payout_groups: 'קבוצות תשלום',
  commissions: 'עמלות',
  affiliate_details: 'פרטי שותף',
  creative_empty_desc: `אין קראייטיב.`,
  country_code_required: 'דרוש מדינה בהרשמה',
  custom_docs: 'מסמכים בהתאמה אישית',
  APPROVED: 'מאושר',
  REJECTED: 'נדחה',
  PENDING: 'ממתין לאישור',
  NEEDS_ATTENTION: 'דורש תשומת לב',
  INCOMPLETE: 'לא הושלם',
  EXPIRED: 'פג תוקף',
  update_status: 'עדכן סטטוס',
  field_type: 'סוג שדה',
  options: 'אפשרויות',
  invalid_format: 'פורמט לא תקין עקוב אחרי הדוגמה',
  select_helper: 'הפרד את האפשרויות באמצעות פסיק לדוגמה זכר,נקבה',
  select_files: 'בחר קבצים',
  drop_files_here_or_click_browse_through_your_machine:
    'השלך קבצים כאן או לחץ על כאן כדי לבחור קבצים',
  remove_all: 'הסר הכל',
  upload_files: 'העלה קבצים',
  no_documents_required: 'אין צורך במסמכים',
  admin_status_changed_email: 'סטטוס אדמין שונה',
  admin_status_approved_email: 'סטטוס אמין אושר',
  when_company_requires_documents_upload_here:
    'כאשר החברה מחייבת מסמכים, יידרש לך להעלות אותם כאן',
  single_campaign_mode: 'מצב קמפיין בודד',
  single_campaign_mode_off: 'כל הקמפיינים נגישים.',
  single_campaign_mode_on:
    'הגישה מוגבלת לקמפיין ברירת מחדל (של החברה או של המשתמש).',
  country_grouping: 'קיבוץ מדינות',
  grouping: 'קבוצות',
  create_grouping: 'צור קבוצה',
  symbols: 'מטבעות',
  symbol_grouping: 'קיבוץ מטבעות',
  last_update: 'עודכן לאחרונה',
  create_symbol: 'צור מטבע',
  default_status: 'סטטוס ברירת מחדל',
  payout_type: 'סוג תשלום',
  confirmation: 'אשר',
  copy_short_url: 'העתק לינק מקוצר',
  generate_short_url: 'צור לינק מקוצר',
  short_url: 'לינק מקוצר',
  after_activation: 'ברוכים הבאים (האימייל ישלח רק במידה וה-BODY קיים)',
  hideOrderId: 'הסתר מספר הזמנה (Affiliate)',
  hideAmount: 'הסתר מחיר (Affiliate)',
  users: 'משתמשים',
  forex: 'פיננסי',
  add_user: 'הוסף משתמש',
  edit_user: 'ערוך משתמש',
  first_deposit_date: 'תאריך הפקדה ראשונית',
  lead_id: 'Lead ID',
  user_id: 'מזהה משתמש',
  balance: 'יתרה',
  volume: 'נפח',
  lastAffiliateGroupChangeDate: 'תאריך שינוי קבוצה אחרון',
  firstDepositDate: 'תאריך הפקדה ראשונית',
  account_id: 'מספר חשבון',
  account_currency: 'מטבע חשבון',
  add_account: 'הוסף חשבון',
  edit_account: 'ערוך חשבון',
  add_transaction: 'הוסף עסקה',
  edit_transaction: 'ערוך עסקה',
  accounts: 'חשבונות',
  account: 'חשבון',
  forex_account: 'חשבון פורקס',
  profit: 'רווח',
  symbol: 'סמל',
  transactions: 'עסקאות',
  transaction_date: 'תאריך עסקה',
  open_price: 'מחיר פתיחה',
  close_price: 'מחיר סגירה',
  show_symbols: 'הצג מטבעות',
  qualified: 'Qualified',
  leverage: 'Leverage',
  symbol_payout_modal_title: 'Add Price For Symbol',
  symbol_payout_modal_desc: 'Add Price For Symbol',
  position_count: 'Position Count',
  net_deposit: 'הפקדה נטו',
  ask_price: 'מחיר מבוקש',
  bid_price: 'מחיר רכישה',
  transaction_type: 'סוג העסקה',
  broker_commission: 'עמלת ברוקר',
  revshare_type: 'Revshare Type',
  currency_price: 'Currency Price',
  converted_volume: 'Converted Volume',
  more_options: 'הצג עוד',
  financial_users: 'משתמשים פיינסים',
  financial_accounts: 'חשבונות פיינסים',
  first_time_deposit: 'הפקדות ראשוניות',
  currency_change_title: 'התראה: אישור שינוי מטבע',
  new_country_grouping: 'New Country Group',
  custom_field_created: 'Custom Field Created',
  new_symbol_grouping: 'New Symbol Group',
  assign_personal_payouts: 'הקצה תשלום אישי',
  affiliate_platform_name: 'שם פלטפורמת שותפים',
  currency_change_desc:
    'אתה עומד לשנות את המטבע של החברה שלך. שים לב שפעולה זו תפסיק להיחשב עבור חישובים שלך הקשורים למטבע הקודם. נא קח רגע לבדיקה ועדכון של כל עסקאות או ניתוחים כספיים פעילים. אם יש לך שאלות או צורך בעזרה, אנו כמובן כאן לעזור. תודה על תשומת הלב לנושא זה.',
  tax_id: 'מספר חברה',
  allow_ip_explain:
    'רק פוסטבקים מכתובות ה-IP הללו יורשו. ה-IPs צריכים להיות מופרדים בפסיק. לדוגמה: 127.0.0.1, 192.17.17.1. השאירו את רשומה ריקה אם ברצונכם לאפשר את כל הכתובות ה-IP.',
  setup_included: 'התקנה כלולה',
  notes: 'הערות',
  click_id: 'click id',
  logs: 'לוגים',
  ip: 'כתובת IP',
  parameters: 'פרמטרים',
  top_affiliates: 'שותפים מובילים',
  audit: 'שינויים',
  info: 'מידע',
  entity_id: 'Entity ID',
  entity_class: 'Entity Class',
  activity_type: 'סוג',
  two_factor_auth_dialog_title: 'בקשת אימות דו-שלבי',
  enable: 'אפשר',
  two_factor_auth_dialog_description:
    'המשתמש הנוכחי הפעיל אימות דו-שלבי (2FA) לבטיחות נוספת. כדי להמשיך עם הפעולה המבוקשת, יש להזין את הקוד החד פעמי (OTP) שנוצר על ידי אפליקציית האימות.',
  '2fa': 'אימות דו-שלבי (2FA)',
  enter_6_digit_code: 'הזן קוד בעל 6 ספרות',
  INVALID_2FA_TOKEN: 'קוד 2FA לא חוקי',
  payout_automation: 'אוטומציה של תשלומים',
  add_payout_automation: 'הוסף אוטומציה לתשלום',
  automation_type: 'סוג האוטומציה',
  original_payout_setting: 'הגדרת תשלום מקורית',
  campaign_payout_automation_tiers: 'רמות אוטומציה של תשלום קמפיין',
  payout_tiers: 'Payout Tiers',
  tier_threshold: 'Tier Threshold',
  threshold: 'Threshold',
  are_you_sure_you_want_to_run_this_automation:
    'האם אתה בטוח שאתה רוצה להיץ אוטומטציה?',
  run: 'הפעל',
  last_run_date: 'הפעלה אחרונה',
  campaign_payout_automation_title: 'אוטומצית תשלום לשותפים',
  campaign_payout_automation_subtitle:
    'עם הכלי האוטומטי תוכלו להגיר תשלומים שונים לפי הביצועים של השותפים',
  invalidPasswordMessage:
    'הסיסמה צריכה לכלול לפחות אות אחת, ספרה אחת, ותו מיוחד אחד (!@#$%^&*()_+), ולהיות באורך 10 עד 20 תווים.',
  webhook: 'Webhook',
  webhooks: 'Webhooks',
  webhook_logs: 'Webhook Logs',
  please_add_a_payment_method: 'לשותף לא מוגדר אמצעי תשלום',
  balance_sheet: 'דוח מאזן',
  paid_payouts: 'שולם',
  pending_payouts: 'תשלומים בהמתנה',
  pending_manual: 'Pending Manual',
  approved_manual: 'Approved Manual',
  assign_affiliates: 'הקצאת שותפים',
  create_affiliate_url: 'צור קישור שותף',
  become_an_advertiser: 'הרשם כמפרסם',
  become_an_advertiser_desc:
    'מלא את הטופס מטה כדי לבקש להצטרף לפלטפורמה כמפרסם',
  become_an_advertiser_res:
    'תודה שהגשת את הבקשה. לאחר האישור, תקבל פרטי כניסה במייל',
  advertiser_registrations: 'רישומי מפרסמים',
  allow_affiliate_register: 'אפשר רישום שותפים',
  allow_advertiser_register: 'אפשר רישום מפרסמים',
  advertiser_user_management: 'ניהול מפרסמים',
  advertiser_login: 'כניסת מפרסם',
  advertiser_user_login_desc:
    'שים לב שכל פעולה שתבוצע לאחר הכניסה היא באחריותך ויכולה להשפיע על חשבון המפרסם.',
  edit_advertiser_user: 'ערוך מפרסם',
  advertiser_user_updated: 'משתמש עודכן',
  add_advertiser_user: 'הוסף מפרסם',
  create_advertiser_user: 'צור מפרסם',
  login_as_advertiser: 'התחברות כמפרסם',
  login_as_admin: 'התחברות כמנהל',
  adv_registration_url: 'קישור מפרסם',
  free_trial_ending: 'תקופת הניסיון מסתיימת {0}',
  email_config_activation: 'הפעלה',
  email_config_reset_password: 'איפוס סיסמה',
  email_config_changed_email: 'שינוי אימייל',
  email_config_welcome:
    'ברוך הבא (האימייל ישלח רק במידה וקיים תוכן בגוף האימייל)',
  email_config_request_to_join_campaign_approved: 'בקשת הצטרפות לקמפיין אושרה',
  email_config_request_to_join_campaign_declined: 'בקשת הצטרפות לקמפיין נדחתה',
  email_config_admin_status_approved: 'סטטוס מנהל אושר',
  email_config_admin_status_changed: 'סטטוס מנהל שונה',
  email_config_payment_request_approved: 'בקשת התשלום אושרה',
  email_config_payment_request_declined: 'בקשת התשלום נדחתה',
  custom_status: 'סטטוס מותאם אישית',
  integrations: 'אינטגרציות',
  profile_pic: 'תמונת פרופיל',
  custom_statuses: 'סטטוסים מותאמים אישית',
  add_custom_status: 'הוסף סטטוס מותאם אישית',
  edit_custom_status: 'ערוך סטטוס מותאם אישית',
  ip_cooldown: 'תקופת קירור IP',
  ip_cooldown_helper: 'תקופת ההמתנה בין כל המרה לכתובת IP נתונה.',
  instant: 'מיידי',
  one_hour: 'שעה אחת',
  one_day: 'יום אחד',
  one_week: 'שבוע אחד',
  one_month: 'חודש אחד',
  one_year: 'שנה אחת',
  primary_color: 'צבע ראשי',
  secondary_color: 'צבע משני',
  dark_mode: 'מצב כהה',
  light_mode: 'מצב בהיר',
  free_mode: 'מצב חופשי',
  use_a_semicolon:
    'השתמש בנקודה פסיק להוספת מספר זוגות מטבע, למשל: AUDCAD;EURUSD',
  new_symbols_will_be_added:
    'סמלים חדשים יתווספו באופן אוטומטי עם צעד 4 נקודות (ניתן לשנות מאוחר יותר)',
  group_name: 'שם הקבוצה',
  send_as_newsletter: 'שלח כניוזלטר',
  send_as_newsletter_desc: `עלון זה יישלח כאימייל לכל השותפים הפעילים (אם נבחר קמפיין, רק השותפים בקמפיין יקבלו אימייל). פעולה זו אינה ניתנת לביטול ותעבוד רק אם לחברה יש הגדרת SMTP פעילה.
`,
  affiliate_emails: 'מיילים לשותפים',
  invoice_details: 'פרטי החשבונית',
  display_deposits_and_withdrawals: 'הצג הפקדות ומשיכות כברירת מחדל',
  display_accessibility_menu: 'הצגת תפריט נגישות',
  lifetime_affiliates: 'שותפים לכל החיים',
  customer_id: 'מזהה לקוח',
  leads: 'לידים',
  lead_created: 'ליד נוצר',
  lead_updated: 'ליד עודכן',
  add_lead: 'הוסף ליד',
  create_lead: 'צור ליד',
  edit_lead: 'ערוך ליד',
  NEW: 'חדש',
  CONTACTED: 'יצר קשר',
  QUALIFIED: 'הוסמך',
  CONVERTED: 'הומר',
  CLOSED: 'סגור',
  mlm_type: 'סוג MLM',
  brand_banner: 'באנר מותג',
  add_to_dashboard: 'הוסף לדאשבורד',
  remove_from_dashboard: 'הסר מדאשבורד',
  edit_new_creative: 'ערוך יצירה חדשה',
  remove_affiliate_withdrawal_request: 'הסר בקשת משיכת שותף',
  remove_withdrawal_info_helper:
    'כאשר אתה מפעיל אפשרות זו, לחצן בקשת המשיכה המשויכת יוסר מפורטל השותפים',
  campaign_name_clone: 'אשר שכפול {campaignTitle}',
  clone: 'שכפול',
  campaign_clone_message:
    'אתה עומד לשכפל את הקמפיין הזה. שים לב לדברים הבאים: \n \u200B \n1. השותפים לא ישוכפלו. \n2. רק הגדרות הקשורות למעקב, הגדרה כללית ותשלומים יועתקו.\n3. הקמפיין המשוכפל יהיה במצב השהיה. \n \u200B \n האם אתה בטוח שברצונך להמשיך בשכפול?',
  automatically: 'אוטומטית',
  by_affiliate_request: 'לפי בקשת שותף',
  choose_how_to_handle_payouts:
    'בחר כיצד לנהל את התשלומים. אוטומטית או לפי בקשת שותף.',
  period_type: 'סוג תקופה',
  day_of_month: 'יום בחודש',
  day_of_week: 'יום בשבוע',
  allow_custom_amount_helper:
    'הפעל אפשרות זו כדי לאפשר לשותפים להזין סכום תשלום ידנית.',
  allow_attachment_helper: 'הפעל אפשרות זו כדי לאפשר צירוף בקשה לתשלום.',
  allow_enter_amount: 'אפשר הזנת סכום',
  allow_an_attachment: 'אפשר צירוף',
  form_editor: 'עורך טופס',
  form_preview: 'תצוגה מקדימה של הטופס',
  lifetime_lifecycle: 'מחזור חיים לכל החיים',
  lifetime_lifecycle_helper:
    'מוסיף תאריך תפוגה לשותפים לכל החיים. חל רק על שותפים לכל החיים שנוצרו לאחר השינוי',
  has_expiration_date: 'יש תאריך תפוגה',
  allow_api_keys_by_default: 'לאפשר מפתחות API כברירת מחדל',
  allow_api_keys_helper: 'שינוי זה לא ישפיע על השותפים הקיימים',
  subscriptionMessage: 'תשלום על סך של {price} ייגבה מחשבונך.',
  subscriptionMessage2: '* המנוי יתחדש באופן אוטומטי ללא הודעה עד לביטולו.',
  tiers_migration: 'רמות',
  bonus: 'בונוס',
  deposits_count: 'הפקדות',
  registrations_count: 'הרשמות',
  deposits_amount: 'סכום הפקדות',
  threshold_reward_type: 'סוג תגמול סף',
  campaign_payout_automation_bonuses: 'בונוסים לאוטומציה של תשלומי קמפיין',
  recurring_bonus: 'בונוס חוזר',
  recurring_bonus_helper_text:
    'כאשר פעיל, הבונוס מחושב חודשי. כאשר כבוי, זה בונוס חד פעמי לכל שותף.',
  campaign_payout_automation_desc_evaluation:
    '<strong>הערכת ביצועים:</strong> בתחילת כל חודש, המערכת מחשבת את ביצועי השותפים באמצעות מדדים מרכזיים כמו כמות המרות, נפח וספירת המרות.',
  campaign_payout_automation_desc_performance_tiers:
    '<strong>רמות ביצועים עם ערכי סף:</strong> השותפים מוקצים לרמות ספציפיות על פי הביצועים שלהם יחסית לערכי הסף שהוגדרו מראש.',
  campaign_payout_automation_desc_rewarding_performance:
    '<strong>התאמות תשלומים:</strong> שותפים שעוברים את היעדים שלהם מהחודש הקודם מקודמים אוטומטית לרמת תשלומים גבוהה יותר.',
  campaign_payout_automation_desc_personalized_payouts:
    '<strong>בונוסים לשותפים:</strong> שותפים מרוויחים בונוסים על ידי עמידה בערכי סף ספציפיים. אם ישנם כמה בונוסים זמינים במסגרת אוטומציה אחת, המערכת מעניקה את הבונוס הקשור לערך הסף הגבוה ביותר שהושג. סכום הבונוס מוגדר בהגדרות תשלום הבונוסים.',
  estimated_original_price: 'הערכת מחיר מקורי',
  conversion_rate: 'שיעור המרה',
  igaming_reports: 'דוחות iGaming',
  clicks_to_registrations: 'קליקים להרשמות',
  registrations_to_deposits: 'הרשמות להפקדות',
  clicks_to_deposits: 'קליקים להפקדות',
  fraud_score: 'סיכוי להונאה',
  import_conversions: 'יבא המרות',
  update_conversions: 'עדכן המרות',
  if_commission_not_specified:
    'אם לא צוין, העמלות יותאמו אוטומטית בהתאם לסכום.',
  should_affiliates_change:
    'האם השינוי בשותף צריך להתבצע לכל החשבונות תחת המשתמש שיש להם כעת את השותף הקודם הזהה?',
  what_is_your_business_type: 'מהו סוג העסק?',
  request_an_account: 'בקשה לפתיחת חשבון',
  min_amount: 'סכום מינימלי',
  view_more: 'הצג עוד',
  receive_updates: 'אני מסכימ/ה לקבל עדכונים והצעות למייל',
  campaign_tracking_tools: 'כלי מעקב',
  campaign_setup: 'הגדרה/התקנה',
  affiliate_assignment: 'שיוך שותפים',
  categories: 'קטגוריות',
  login_hero_title1: 'פתרון מותאם אישית',
  login_hero_subtitle1: 'אנחנו מספקים מערכת הכל כלול לניהול רשת שותפים.',
  login_hero_title2: 'ניהול עמלות מתקדם',
  login_hero_subtitle2: 'עמלות מותאמות אישית לפי שותף, קבוצות, רמות ויעדים.',
  login_hero_title3: 'ממשק משתמש מתקדם',
  login_hero_subtitle3: 'ממשק מעוצב וממותג עם אפשרויות התאמה אישית.',
  login_hero_title4: 'פונקציונליות חדשנית',
  login_hero_subtitle4:
    'אפשרויות מתקדמות המגדירות סטנדרטים חדשים: שיווק רב-שכבתי (MLM), Anti Fraud, אוטומציה של תשלומים ועוד!',
  mlm_settings: 'שיווק רב שכבתי (MLM)',
  customize: 'התאמה אישית',
  platform_translation_management: 'ניהול שפות',
  platform_translation_desc: `התאמה אישית לתרגומים. לחץ פעמיים על תא כדי לערוך תרגום. שים לב שהשינויים הם באחריותך ויכולים להשפיע על חווית המשתמש. ודא שהמיקומים (כגון {placeholder} או [1]) נשארים ללא שינוי על מנת להציג את הטקסט כראוי.`,
  news: 'חדשות ועדכונים',
  campaign_categories: 'קטגוריות קמפיין',
  default_language_label: 'שפת ברירת מחדל',
  select_default_language: 'בחר שפת ברירת מחדל',
  affiliate_group: 'קבוצת שותפים',
  add_affiliate_group: 'הוסף קבוצה שותפים',
  edit_affiliate_group: 'ערוך קבוצה שותפים',
  contact_info: 'מידע ליצירת קשר',
  contact_info_desc: 'פרטי הקשר יוצגו לשותפים שלך. שדות ריקים יישארו מוסתרים.',
  affiliate_settings: 'הגדרות שותפים',
  requireAffiliateLegalStatus: 'סטטוס שותף (פרטי או חברה)',
  hide_short_link: 'הסתר אפשרות קישור מקוצר (Affiliate)',
  brand_profit: 'רווח מותג',
  display_conversion_personal_info: 'הצג מידע אישי של המרה',
  registration_settings: 'הגדרות הרשמה',
  requireAffiliate2FA: 'דרוש אימות דו-שלבי לשותף (2FA)',
  requireCompanyName: 'דרוש שם חברה בהרשמה',
  affiliate_promotional_material: 'הסכמה לשליחת חומר פרסומי',
  agree_to_promotional_material: 'אישור שליחת חומר פרסומי',
  allow_leads_by_default: 'אפשר לידים כברירת מחדל',
  display_registration_popup: 'הצג הודעת (Activation) בחלון קופץ',
  display_registration_popup_helper:
    'הפעל אפשרות זו כדי להציג חלון קופץ להפעלה בהרשמה במקום ההודעה הרגילה.',
  add_shortcut: 'הוסף קיצור דרך',
  search_icons: 'חפש אייקונים',
  edit_shortcut: 'ערוך קיצור דרך',
  latest_leads: 'לידים אחרונים',
  registrations: 'הרשמות',
  top_campaign_payouts: 'הגדרות תשלום מובילות'
};
