import axios from 'axios';
import { store } from 'src';
import ApiPaths from '../utils/ajaxUtils';
import { deleteRequest, get, post, put } from '../utils/axios';
import { rebuildCompanySettings } from '../utils/utils';

export const COMPANY_TRAFFIC_SOURCE = '@company/traffic-sources';

export const COMPANY_ADVERTISERS = '@company/advertisers';

export const COMPANY_NOTI = '@company/noti';

export const COMPANY_CURRENCY = '@company/currency';

export const COMPANY_BRANDING = '@company/branding';

export const COMPANY_HAS_BRANDING = '@company/has-branding';

export const COMPANY_REACHED_MAX_CAMPAIGNS = '@company/reached-max-campaigns';

export const COMPANY_TOGGLE_ONBOARDING = '@company/toggle-onboarding';

export const COMPANY_INITIAL_DATA = '@company/initial-data';
export const COMPANY_PAYMENT_METHOD = '@company/payment-method';

export const COMPANY_CAMPAIGN_NEXT_AVAILABLE_SERIAL_OID =
  '@company/next-available-serial-oid';

export const COMPANY_MINIMUM_WITHDRAWAL_AMOUNT =
  '@company/minimum-withdrawal-amount';
export const COMPANY_MLM_SETTINGS = '@company/mlm-settings';

export function getTrafficSources() {
  const request = get(ApiPaths.companyActions.trafficSources, {});
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_TRAFFIC_SOURCE,
            payload: response.data
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}
export function getAdvertisersSummary() {
  const request = get(ApiPaths.companyActions.advertisers, {});
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_ADVERTISERS,
            payload: response.data
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export function getCurrency() {
  const request = get(ApiPaths.companyActions.currency, {});
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_CURRENCY,
            payload: { sign: response.data.sign, ...response.data }
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export async function getAllCurrencies() {
  const request = await get(`${ApiPaths.companyActions.currency}/all`, {});
  return request?.data;
}

export function updateCompanyCurrency(currency) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const updatedCurrency = await put(
        `${ApiPaths.companyActions.currency}`,
        currency
      );
      dispatch({
        type: COMPANY_CURRENCY,
        payload: { sign: updatedCurrency.data.sign, ...updatedCurrency.data }
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateIfCompanyReachedMaxCampaigns() {
  const request = get(
    `${ApiPaths.companyActions.settings}/campaign-limit-reached`
  );
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_REACHED_MAX_CAMPAIGNS,
            payload: response.data
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}
export async function toggleOnboarding() {
  const response = await put(`${ApiPaths.companyActions.settings}/onboarding`);
  if (!response.data?.error) {
    store.dispatch({
      type: COMPANY_TOGGLE_ONBOARDING,
      payload: response.data
    });
  }

  return response.data;
}

export const getCompanyAffiliates = async () => {
  const response = await get(`${ApiPaths.companyActions.affiliates}`);
  return response?.data;
};

export const getCompanyAffiliatesAsMiniList = async () => {
  const response = await get(`${ApiPaths.companyActions.affiliates}/options`, {
    getManagers: false
  });
  return response?.data;
};

export const getCompanyManagersAsMiniList = async () => {
  const response = await get(`${ApiPaths.companyActions.affiliates}/options`, {
    getManagers: true
  });
  return response?.data;
};

export const getCompanyManagers = async () => {
  const response = await get(
    `${ApiPaths.companyActions.affiliates}?isManagers=true`
  );
  return response?.data;
};

export const updateCompanySettings = values => {
  values = rebuildCompanySettings(values);
  return put(`${ApiPaths.companyActions.settings}`, values)
    .then(response => {
      if (response?.data) {
        store.dispatch({
          type: COMPANY_MINIMUM_WITHDRAWAL_AMOUNT,
          payload: response?.data
        });
      }
    })
    .catch(err => {
      console.warn(err);
    });
};

export const updateCompanyMlmSettings = values => {
  return put(`${ApiPaths.companyActions.mlmSettings}`, values)
    .then(response => {
      if (response?.data) {
        store.dispatch({
          type: COMPANY_MLM_SETTINGS,
          payload: response?.data
        });
      }
    })
    .catch(err => {
      console.warn(err);
    });
};

export async function getCompanyMlmSettings() {
  const request = await get(`${ApiPaths.companyActions.mlmSettings}`);
  return request?.data;
}

export async function getCompanySettings() {
  const request = await get(`${ApiPaths.companyActions.settings}`);
  return request?.data;
}

export const getCountriesGrouping = async (page, limit) =>
  get(ApiPaths.groupings.countries.get, { page, limit });

export const createCountriesGrouping = async data =>
  post(ApiPaths.groupings.countries.save, data);

export const updateCountriesGrouping = async data =>
  put(ApiPaths.groupings.countries.update(data?.id), data);

export const deleteCountriesGrouping = async id =>
  deleteRequest(ApiPaths.groupings.countries.update(id));

export const getAvailableCountries = async () =>
  get(ApiPaths.groupings.countries.getAvailable);

export const getSymbolGrouping = async (page, limit) =>
  get(ApiPaths.groupings.symbol.get, { page, limit });

export const createSymbolGrouping = async data =>
  post(ApiPaths.groupings.symbol.save, data);

export const createSymbolGroupingFreehand = async (data, groupName) => {
  post(ApiPaths.groupings.symbol.saveFreeHand, data, groupName);
};

export const updateSymbolGrouping = async data =>
  put(ApiPaths.groupings.symbol.update(data?.id), data);

export const deleteSymbolGrouping = async id =>
  deleteRequest(ApiPaths.groupings.symbol.update(id));

export const getAvailableSymbol = async () =>
  get(ApiPaths.groupings.symbol.getAvailable);

export const getAllSymbols = async () => get(ApiPaths.groupings.symbol.getAll);

export const getSymbolOptions = async () =>
  get(ApiPaths.groupings.symbol.options);

export const getCountryOptions = async () =>
  get(ApiPaths.groupings.countries.options);

export const saveSymbol = async data =>
  post(ApiPaths.groupings.symbol.symbol.save, data);

export const updateSymbol = async data =>
  put(ApiPaths.groupings.symbol.symbol.save, data);

export const getAudit = async (filters, pageNumber = 0, pageSize = 10) => {
  const response = await get(ApiPaths.audit, {
    ...filters,
    pageNumber,
    pageSize
  });
  return response?.data;
};

export const getPlanBenefits = async lang => {
  const response = await axios.get(
    `${process.env.REACT_APP_LANDING_PAGE_URL}/${lang}/plan-features`
  );

  return response;
};
