import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, SvgIcon, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  queryField: {
    width: '100%',
    marginRight: theme.spacing(1)
  }
}));

function SearchBar({
  handleQueryChange,
  placeholder,
  query,
  maxWidth = 530,
  sx = {}
}) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.queryField}
      sx={{ maxWidth, ...sx }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon fontSize="small" color="action">
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        )
      }}
      onChange={handleQueryChange}
      placeholder={placeholder}
      value={query}
      variant="outlined"
    />
  );
}

SearchBar.propTypes = {
  handleQueryChange: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string
};

export default SearchBar;
