import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { displayUpdateSnackbar, stopPropagate } from '../utils/utils';
import {
  updateUserMenuShortcuts,
  createUserMenuShortcuts
} from '../actions/userActions';
import IconSelector from 'src/components/IconSelector';
import { DEFAULT_SHORCUT_ICON } from '../utils/staticObjects';

const NewUserCustomMenuShortcutModal = ({
  isOpen,
  fetchData,
  setIsOpen,
  shortcutToEdit
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const {
    handleSubmit: handleModalSubmit,
    control: controlModal,
    reset: resetModal,
    setValue: setValueModal,
    getValues
  } = useForm({
    defaultValues: {
      title: '',
      url: window.location.pathname
    }
  });

  useEffect(() => {
    if (shortcutToEdit && shortcutToEdit?.title) {
      setValueModal('title', shortcutToEdit?.title);
      setValueModal('url', shortcutToEdit?.url);
    } else {
      resetModal();
    }
  }, [shortcutToEdit, setValueModal, resetModal]);

  const handleClose = updateData => {
    if (updateData) {
      fetchData();
    }
    setIsOpen(false);
    resetModal();
  };

  const onSubmit = async data => {
    if (shortcutToEdit) {
      await updateUserMenuShortcuts({
        ...shortcutToEdit,
        title: data?.title,
        url: data?.url,
        icon: !!data?.icon ? data?.icon : shortcutToEdit?.icon
      });
    } else {
      await createUserMenuShortcuts(data);
    }
  };

  const handleFormSubmit = data => {
    onSubmit(data)
      .then(async () => {
        displayUpdateSnackbar(enqueueSnackbar, intl, true);
        handleClose(true);
      })
      .catch(() => {
        displayUpdateSnackbar(enqueueSnackbar, intl, false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose(false)}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <DialogTitle>
        <FormattedMessage
          id={shortcutToEdit ? 'edit_shortcut' : 'add_shortcut'}
        />
      </DialogTitle>
      <form onSubmit={stopPropagate(handleModalSubmit(handleFormSubmit))}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="title"
                control={controlModal}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={intl.formatMessage({ id: 'title' })}
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="url"
                control={controlModal}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={intl.formatMessage({ id: 'url' })}
                    variant="outlined"
                    fullWidth
                    helperText={intl.formatMessage({
                      id: 'shortcut_url_helper'
                    })}
                    defaultValue={getValues('url')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <IconSelector
                control={controlModal}
                name="icon"
                setValue={setValueModal}
                initialIcon={shortcutToEdit?.icon ?? DEFAULT_SHORCUT_ICON}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)} color="secondary">
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
          <Button type="submit" color="primary">
            {intl.formatMessage({ id: 'save' })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

NewUserCustomMenuShortcutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  shortcutToEdit: PropTypes.object
};

export default NewUserCustomMenuShortcutModal;
