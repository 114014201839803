import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { IconButton, SvgIcon } from '@mui/material';
import { el } from 'date-fns/locale';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { updateMerchantTheme } from 'src/actions/userActions';
import { THEMES } from 'src/constants';
import useSettings from 'src/hooks/useSettings';
import { accountSelector } from 'src/selectors/selectors';

export default function ThemeModeToggle() {
  const { settings, saveSettings } = useSettings();
  const { user } = useSelector(accountSelector);

  const toggleTheme = useCallback(() => {
    const theme =
      settings?.theme == THEMES.LIGHT.name
        ? THEMES.ONE_DARK.name
        : THEMES.LIGHT.name;
    if (user) {
      updateMerchantTheme(theme, saveSettings);
    } else {
      saveSettings({ theme });
    }
  }, [settings, saveSettings, user]);

  return (
    <IconButton color="inherit" onClick={toggleTheme} size="large">
      <SvgIcon fontSize="small">
        {settings?.theme == THEMES.LIGHT.name ? (
          <DarkModeOutlinedIcon />
        ) : (
          <LightModeOutlinedIcon />
        )}
      </SvgIcon>
    </IconButton>
  );
}
