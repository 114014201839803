import React, { useState } from 'react';
import {
  TextField,
  Box,
  Grid,
  IconButton,
  Typography,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Icon } from '@iconify/react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounceEffect } from './useDebounceEffect';
import Iconify from './iconify';

const MAX_ICONS = 100;
const PAGE_SIZE = 10;

const IconSelector = ({ control, name, setValue, initialIcon }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [icons, setIcons] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState(initialIcon);
  const [hasSearched, setHasSearched] = useState(false);
  const intl = useIntl();

  const fetchIcons = async () => {
    try {
      const response = await fetch(
        `https://api.iconify.design/search?query=${searchTerm}&limit=${MAX_ICONS}`
      );
      const data = await response.json();

      if (data.icons) {
        setIcons(data.icons.slice(0, MAX_ICONS));
      } else {
        setIcons([]);
      }

      setPage(0);
      setSelectedIcon(initialIcon);
      setValue(name, '');
      setHasSearched(true);
    } catch (error) {
      console.error('Error fetching icons:', error);
    }
  };

  const handleSearch = () => {
    if (searchTerm) {
      fetchIcons();
    } else {
      setIcons([]);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) setPage(page - 1);
  };

  const handleNextPage = () => {
    if ((page + 1) * PAGE_SIZE < icons.length) setPage(page + 1);
  };

  const toggleIconSelection = icon => {
    setSelectedIcon(prev => {
      const newIcon = prev === icon ? initialIcon : icon;
      setValue(name, newIcon);
      return newIcon;
    });
  };

  const currentIcons = icons.slice(
    page * PAGE_SIZE,
    page * PAGE_SIZE + PAGE_SIZE
  );

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  useDebounceEffect(
    () => {
      if (searchTerm === null) return;
      handleSearch(searchTerm);
    },
    [500],
    [searchTerm]
  );

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            border={theme => `1px solid ${theme.palette.primary.main}`}
            borderRadius={2}
            padding={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Iconify icon={selectedIcon} width="36" sx={{ fontSize: 36 }} />
          </Box>
        </Grid>
        {/* Search Input */}
        <Grid item xs={10}>
          <TextField
            fullWidth
            value={searchTerm}
            onKeyDown={handleKeyDown}
            onChange={e => setSearchTerm(e.target.value)}
            label={intl.formatMessage({ id: 'search_icons' })}
            variant="outlined"
          />
        </Grid>

        {/* Icons or No Results Message */}
        {hasSearched && (
          <Grid item xs={12}>
            {icons.length === 0 ? (
              // No Results Found
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100px"
              >
                <Typography variant="h6" color="textSecondary">
                  {intl.formatMessage({ id: 'no_results_found' })}
                </Typography>
              </Box>
            ) : (
              // Icon Grid
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                my={2}
              >
                {/* Pagination Controls */}
                <IconButton onClick={handlePrevPage} disabled={page === 0}>
                  <ArrowBackIcon />
                </IconButton>

                {/* Icons */}
                <Grid container spacing={2} justifyContent="center">
                  {currentIcons.map(icon => (
                    <Grid item key={icon}>
                      <Box
                        onClick={() => toggleIconSelection(icon)}
                        border={
                          selectedIcon === icon
                            ? theme => `2px solid ${theme.palette.primary.main}`
                            : theme => `1px solid ${theme.palette.grey[300]}`
                        }
                        borderRadius={2}
                        padding={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          cursor: 'pointer',
                          width: '80px',
                          height: '80px'
                        }}
                      >
                        <Icon icon={icon} width="48" height="48" />
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                <IconButton
                  onClick={handleNextPage}
                  disabled={(page + 1) * PAGE_SIZE >= icons.length}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default IconSelector;
