/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: { oid: 0, name: 'LIGHT' },
  ONE_DARK: { oid: 1, name: 'ONE_DARK' },
  UNICORN: { oid: 2, name: 'UNICORN' }
};

export const LanguagesEnum = {
  EN: { value: 0, label: 'English', direction: 'ltr', lang: 'en', flag: 'gb' },
  IW: { value: 1, label: 'עברית', direction: 'rtl', lang: 'iw', flag: 'il' },
  RU: { value: 2, label: 'Русский', direction: 'ltr', lang: 'ru', flag: 'ru' },
  ES: { value: 3, label: 'Español', direction: 'ltr', lang: 'es', flag: 'es' },
  SE: { value: 4, label: 'Svenska', direction: 'ltr', lang: 'se', flag: 'se' }
};

export const PlatformLanguagesEnum = {
  EN: {
    value: 0,
    label: 'English',
    direction: 'ltr',
    lang: 'en',
    flag: 'gb'
  },
  RU: {
    value: 2,
    label: 'Русский',
    direction: 'ltr',
    lang: 'ru',
    flag: 'ru'
  },
  ES: {
    value: 3,
    label: 'Español',
    direction: 'ltr',
    lang: 'es',
    flag: 'es'
  },
  SE: {
    value: 4,
    label: 'Svenska',
    direction: 'ltr',
    lang: 'se',
    flag: 'se'
  },
  DE: {
    value: 5,
    label: 'Deutsch',
    direction: 'ltr',
    lang: 'de',
    flag: 'de'
  },
  IN: {
    value: 6,
    label: 'Indonesian',
    direction: 'ltr',
    lang: 'in',
    flag: 'id'
  },
  FR: {
    value: 7,
    label: 'Français',
    direction: 'ltr',
    lang: 'fr',
    flag: 'fr'
  },
  JP: {
    value: 8,
    label: '日本語',
    direction: 'ltr',
    lang: 'jp',
    flag: 'jp'
  },
  CN: {
    value: 9,
    label: '中文',
    direction: 'ltr',
    lang: 'cn',
    flag: 'cn'
  },
  Pl: {
    value: 11,
    label: 'Polski',
    direction: 'ltr',
    lang: 'pl',
    flag: 'pl'
  },
  VN: {
    value: 12,
    label: 'Tiếng Việt',
    direction: 'ltr',
    lang: 'vn',
    flag: 'vn'
  },
  IW: {
    value: 1,
    label: 'עברית',
    direction: 'rtl',
    lang: 'iw',
    flag: 'il'
  },
  AR: {
    value: 10,
    label: 'عربي',
    direction: 'rtl',
    lang: 'ar',
    flag: 'sa'
  }
};

// recieves 'en' or 'iw'
export const getLanguageByKey = languageKey =>
  LanguagesEnum[languageKey?.toUpperCase()];

export const getLanguageByValue = languageValue => {
  let result;
  for (const lang in LanguagesEnum) {
    if (LanguagesEnum[lang].value === languageValue) {
      result = LanguagesEnum[lang];
    }
  }
  return result;
};
