/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  DATAGRID_STATE,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  PASSWORD_CHANGED,
  PERSONALIZATION,
  SHORTCUTS,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE
} from 'src/actions/userActions';

const initialState = {
  user: null,
  datagrid: {},
  shortcuts: [],
  errorMessage: '',
  avatarChanged: false
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
      });
    }
    case SILENT_LOGIN:
    case LOGIN_SUCCESS: {
      const { entities, result } = action.payload;

      return produce(state, draft => {
        draft.user = entities.users[result];
      });
    }
    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = null;
        draft.datagrid = {};
      });
    }

    case UPDATE_PROFILE: {
      const user = action.payload.data;
      return produce(state, draft => {
        draft.user = user;
      });
    }
    case PERSONALIZATION: {
      const personalization = action.payload;
      return produce(state, draft => {
        draft.user.personalization = personalization;
      });
    }
    case SHORTCUTS: {
      return produce(state, draft => {
        draft.shortcuts = action.payload?.data;
      });
    }
    case UPDATE_PROFILE_IMAGE: {
      return produce(state, draft => {
        draft.user.profileImage = action.payload;
      });
    }

    case PASSWORD_CHANGED: {
      return produce(state, draft => {
        draft.passwordChaned = true;
      });
    }
    case DATAGRID_STATE: {
      return produce(state, draft => {
        draft.datagrid = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
