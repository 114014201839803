import axios from 'axios';
import qs from 'qs';
import serverSettings from './serverSettings';

// set default server url to requests
axios.defaults.baseURL = `${serverSettings.path}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  'accessToken'
)}`;

// instance to use in axios
export const instance = axios.create();

export const post = (url, data, params, headers) =>
  axios({
    method: 'post',
    url,
    data,
    params,
    headers
  });

export const put = (url, data, params) =>
  axios({
    method: 'put',
    url,
    data,
    params
  });

export const get = (url, params, axiosProps) =>
  axios({
    method: 'get',
    url,
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    ...axiosProps
  });

export const deleteApiCall = (url, params) =>
  axios({
    method: 'delete',
    url,
    params
  });

export const patch = (url, params) =>
  axios({
    method: 'patch',
    url,
    params
  });

export const getExcel = async (url, params) => {
  axios.defaults.headers.common['Content-Type'] = 'application/octet-stream';
  const data = await axios({
    method: 'get',
    url,
    params,
    headers: {
      'content-type': 'application/octet-stream',
      accept: 'application/octet-stream'
    }
  });
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  return data;
};

export const deleteRequest = (url, params) =>
  axios({
    method: 'delete',
    url,
    params
  });

export const downloadByGet = (url, params) =>
  axios({
    method: 'get',
    url,
    params,
    responseType: 'blob'
  });

export const serializeArray = (array, paramName) => {
  let encodedArray = encodeURI(
    array.reduce((lastValue, value) => `${lastValue}${value},`, `${paramName}=`)
  );
  if (encodedArray.lastIndexOf(',') === encodedArray.length - 1) {
    encodedArray = encodedArray.substring(0, encodedArray.length - 1);
  }
  return encodedArray;
};

export default instance;

export const download = url =>
  axios({
    method: 'get',
    url,
    responseType: 'blob' // Set the response type to 'stream' to handle file downloads
  })
    .then(response => {
      const fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/"/g, ''); // Extract the filename from the 'Content-Disposition' header
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();

      console.log(`File downloaded as ${fileName}`);
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
