// eslint-disable-next-line import/prefer-default-export
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import 'moment/min/locales';
import { store } from 'src';
import { getCompanyInitialData } from 'src/actions/configActions';
import countryMap from '../assets/json/countryISO.json';
import { THEMES } from '../constants';
import ApiPaths from './ajaxUtils';
import { get, post } from './axios';
import { PaymentMethodTypesEnum } from './enums/PaymentMethodTypesEnum';
import { CustomFieldTypeEnum } from './enums/SettingEnums';
import { SocialPlatformEnum } from './enums/SocialPlatformEnum';
import { CampaignSettings } from './enums/campaignsEnums';
import { DATE_DEFUALT_FORMAT } from './staticObjects';

var currentTimeZone = moment.tz.guess();

export const rebuildCampaignGeneralSettingsData = (data, file) => {
  const formData = new FormData();
  formData.append(
    'campaignGeneralSettings',
    new Blob([JSON.stringify(data)], {
      type: 'application/json'
    })
  );
  formData.append('file', file);
  return formData;
};

// eslint-disable-next-line import/prefer-default-export
export const rebuildCampaignData = (data, ioDocument, logo) => {
  const campaignSettingsDto = {
    campaignGeneralSettingsDto: {
      title: data.generalSettingsTitle,
      description: draftToHtml(
        convertToRaw(data.generalSettingsDescription.getCurrentContent())
      ),
      campaignTerms: draftToHtml(
        convertToRaw(data.generalSettingsCampaignTerms.getCurrentContent())
      ),
      advertiserSerialOid: data.generalSettingsAdvertiserSerialOid,
      campaignTrafficSources: data.generalSettingsCampaignTrafficSources,
      logoUrl: data.generalSettingsLogoUrl,
      status: data.generalSettingsStatus,
      privacy: data.generalSettingsPrivacy,
      featured: data.generalSettingsFeatured,
      startDate: new Date(data.generalSettingsStartDate),
      endDate:
        data.generalSettingsEndDate !== null
          ? new Date(data.generalSettingsEndDate)
          : null,
      // holdTime: data.generalSettingsHoldTime
      tax: data.generalSettingsTax
    },
    campaignTrackingSettingsDto: {
      trackingUrl: data.trackingSettingsTrackingUrl,
      previewUrl: data.trackingSettingsPreviewUrl,
      // redirectType: data.trackingSettingsRedirectType,
      trafficBackUrl: data.trackingSettingsTrafficBackUrl,
      allowedIps: data.trackingSettingsAllowedIps,
      uniqueIpOnly: data.trackingSettingsUniqueIpOnly,
      allowDifferentAgents: data.trackingSettingsAllowDifferentAgents,
      attributionOid: data.trackingSettingsAttributionOid,
      campaignStats: {
        earnPerClick: 0,
        clickRate: 0,
        clickSessionLifeSpanOid: data.trackingSettingsClickSessionLifeSpan.oid,
        clickSessionDays: data?.trackingSettingsClickSessionDays,
        clickSessionHours: data?.trackingSettingsClickSessionHours
      }
    },
    campaignPayoutsSettingsDtoList: []
  };
  for (let i = 0; i < data.payouts.length; i++) {
    campaignSettingsDto.campaignPayoutsSettingsDtoList[i] = {
      identity: data[CampaignSettings.PAYOUTS][i].payoutsIdentity,
      symbols: data[CampaignSettings.PAYOUTS][i]?.symbols?.map(o => o.value),
      title: data[CampaignSettings.PAYOUTS][i].payoutsSettingsTitle,
      merchantCommission:
        data[CampaignSettings.PAYOUTS][i].payoutsSettingsMerchantCommission,
      affiliateCommission:
        data[CampaignSettings.PAYOUTS][i].payoutsSettingsAffiliateCommission,
      paymentType:
        data[CampaignSettings.PAYOUTS][i].payoutsSettingsPaymentType.name,
      payoutType:
        data[CampaignSettings.PAYOUTS][i].payoutsSettingsPayoutType.name,
      goalValue: data[CampaignSettings.PAYOUTS][i].payoutsSettingsGoalValue,
      couponCode: data[CampaignSettings.PAYOUTS][i].payoutsSettingsCouponCode,
      affiliates: data.companyAffiliates.filter(affiliate =>
        data[CampaignSettings.PAYOUTS][i].payoutsSettingsAffiliates?.some(
          selectedAff => selectedAff.value === affiliate.userSerialOid
        )
      )
    };
  }
  const formData = new FormData();
  formData.append(
    'campaignSettingsDto',
    new Blob([JSON.stringify(campaignSettingsDto)], {
      type: 'application/json'
    })
  );
  formData.append('campaignLogo', logo);
  formData.append('ioDocument', ioDocument);
  return formData;
};

export const rebuildCampaignGeneralSettings = rawData => {
  const data = cloneDeep(rawData);
  const formData = new FormData();
  formData.append('campaignLogo', data.generalSettingsCampaignLogo);
  formData.append('ioDocument', data.generalSettingsIODocument);

  delete data.generalSettingsCampaignLogo;
  delete data.generalSettingsIODocument;
  data.campaignTrafficSources = data?.campaignTrafficSources.map(item => ({
    companyTrafficSourceOid: item
  }));
  formData.append(
    'campaignGeneralSettingsDto',
    new Blob([JSON.stringify(data)], {
      type: 'application/json'
    })
  );
  return formData;
};

export const rebuildConversionDto = conversion => ({
  ...conversion,
  conversionIdentity: conversion.identity,
  orderId: conversion?.orderId,
  creationDate: new Date(conversion.creationDate),
  campaignIdentity: conversion.selectedCampaign,
  amount: conversion.amount,
  merchantCommission: conversion.merchantCommission,
  affiliateCommission: conversion.affiliateCommission,
  status: conversion.selectedStatus,
  affiliateIdentity: conversion.selectedAffiliate.value,
  sub1: conversion.sub1,
  sub2: conversion.sub2,
  sub3: conversion.sub3,
  sub4: conversion.sub4,
  sub5: conversion.sub5,
  sub6: conversion.sub6,
  sub7: conversion.sub7,
  sub8: conversion.sub8,
  comment: conversion.comment,
  campaignPayoutsSettingsObject: conversion.selectedCampaignPayoutSettings,
  campaignGeneralSettingsOid: conversion.selectedCampaignPayoutSettings.oid
});

// todo change by object
export const rebuildPayoutData = payout => ({
  identity: payout.identity,
  advertiserIdentity: payout.advertiserIdentity,
  advertiserName: payout.advertiserName,
  totalAmount: payout.totalAmount,
  totalCommission: payout.totalCommission,
  creationDate: payout.creationDate,
  campaignStartDate: payout.campaignStartDate,
  campaignEndDate: payout.campaignEndDate,
  status: payout.status
});

export const rebuildCompanySettings = companySettingValues => ({
  ...companySettingValues,
  apiAllowedIps: companySettingValues?.apiAllowedIps,
  contactUsEmail: companySettingValues?.contactUsEmail,
  managerAllowedIps: companySettingValues?.managerAllowedIps,
  affiliateNeedsActivation: companySettingValues?.newAffiliateNeedsActivation,
  displayAccessibilityToolbox:
    companySettingValues?.displayAccessibilityToolbox,
  affiliateNeedsManualActivation:
    companySettingValues?.affiliateNeedsManualActivation,
  countryCodeRequired: companySettingValues?.countryCodeRequired,
  minimumWithdrawalAmount: companySettingValues?.minimumWithdrawalAmount,
  taxId: companySettingValues?.taxId,
  defaultManager: {
    userSerialOid: companySettingValues?.defaultManager?.userSerialOid
  },
  mlmActive: companySettingValues?.mlmActive,
  mlmType: companySettingValues?.mlmType,
  mlmPercent: companySettingValues?.mlmPercent,
  mlmLevel2Active: companySettingValues?.mlmLevel2Active,
  mlmLevel2Percent: companySettingValues?.mlmLevel2Percent,
  mlmLevel3Active: companySettingValues?.mlmLevel3Active,
  mlmLevel3Percent: companySettingValues?.mlmLevel3Percent,
  mlmLevel4Active: companySettingValues?.mlmLevel4Active,
  mlmLevel4Percent: companySettingValues?.mlmLevel4Percent,
  mlmLevel5Active: companySettingValues?.mlmLevel5Active,
  mlmLevel5Percent: companySettingValues?.mlmLevel5Percent,
  singleCampaignMode: companySettingValues?.singleCampaignMode,
  requireAffiliateLegalStatus:
    companySettingValues?.requireAffiliateLegalStatus,
  affiliatePromotionalMaterial:
    companySettingValues?.affiliatePromotionalMaterial,
  displayRegistrationPopup: companySettingValues?.displayRegistrationPopup,
  hideAmount: companySettingValues?.hideAmount,
  displayConversionPersonalInfo:
    companySettingValues?.displayConversionPersonalInfo,
  hideOrderId: companySettingValues?.hideOrderId,
  companyName: companySettingValues?.companyName,
  allowAffiliateRegister: companySettingValues?.allowAffiliateRegister,
  allowAdvertiserRegister: companySettingValues?.allowAdvertiserRegister,
  requireCompanyName: companySettingValues?.requireCompanyName,
  sendManagerAlertOnNewAffiliate:
    companySettingValues?.sendManagerAlertOnNewAffiliate,
  sendManagerAlertOnNewAffiliatePayout:
    companySettingValues?.sendManagerAlertOnNewAffiliatePayout,
  affiliateDefaultTransactionsPermissionState:
    companySettingValues?.affiliateDefaultTransactionsPermissionState,
  customCurrencySymbol: companySettingValues?.customCurrencySymbol,
  customCurrencyPlacement: companySettingValues?.customCurrencyPlacement
});

export const rebuildCustomField = customField => ({
  fieldName: customField?.customFieldName,
  required: customField?.customFieldRequired,
  fieldTypeOid: customField?.customFieldType,
  fieldNameHtmlOverride: customField?.fieldNameHtmlOverride,
  options: customField?.options,
  affiliateType:
    customField?.affiliateType == 'all' ? null : customField?.affiliateType
});

export const rebuildPaymentMethod = values => ({
  formConfig: values?.formConfig ? JSON.parse(values?.formConfig) : null,
  name: values.paymentMethodName,
  type: values.type
});

export const rebuildBrandingLogo = (data, paramName = 'brandLogo') => {
  const formData = new FormData();
  formData.append(paramName, data?.[paramName]);
  return formData;
};

export const rebuildBrandingBanner = (data, paramName = 'brandBanner') => {
  const formData = new FormData();
  formData.append(paramName, data?.[paramName]);
  return formData;
};

export const rebuildMerchantSettingsDto = data => ({
  firstName: data?.firstName,
  lastName: data?.lastName,
  phoneNumber: data?.phoneNumber,
  siteThemeOid: THEMES[data.theme].oid,
  languageOid: Number(data.lang),
  timeZone: data?.timeZone,
  timeFormat: data?.timeFormat
});

export const dateToLocalDateTime = jsDate => {
  if (!jsDate) return null;
  try {
    return jsDate.clone().toISOString();
  } catch (e) {
    return new Date(jsDate)
      .toISOString()
      .substring(0, 19)
      .replace(' ', 'T');
  }
};

export const dateToLocalDate = jsDate =>
  new Date(jsDate).toISOString().substring(0, 10);

export const localDateTimeToJsDateTime = javaDate => javaDate.replace('T', ' ');

export const localDateTimeToJsDate = javaDate => javaDate.split('T')[0];
const getMomentLocal = lang => {
  if (!lang) return 'en';
  switch (lang) {
    case 'iw':
      return 'he';
    default:
      return lang;
  }
};
export const setMomentDefaults = (tz, format, lang) => {
  if (tz == null) tz = 'UTC';
  const newFormat = format ?? DATE_DEFUALT_FORMAT;
  moment.defaultFormat = newFormat;
  moment.defaultFormatUtc = newFormat;
  moment.locale(getMomentLocal(lang));
  moment.tz.setDefault(tz);
  currentTimeZone = tz;
};

export const formatUtcDate = date => {
  return moment
    .utc(date)
    .tz(currentTimeZone)
    .format();
};

export const getUtcDate = date => {
  return moment.utc(date).tz(currentTimeZone);
};

export const getUtcDateFromNow = date => {
  return getUtcDate(date).fromNow();
};

export const customFieldValueConverter = customFieldValue => {
  if (customFieldValue === '1') {
    return 'true';
  }
  if (customFieldValue === '0') {
    return 'false';
  }

  return customFieldValue;
};

const extractNumberStringFromBoolean = boolean => {
  if (boolean === true) {
    return '1';
  }
  return '0';
};

export const rebuildUserData = data => {
  const affiliateData = { ...data };
  affiliateData.customFields = data.customFields.map(customField => {
    const dto = {
      id: customField?.id ?? '',
      value:
        customField?.customFieldTypeOid === CustomFieldTypeEnum.CHECK_BOX.oid
          ? extractNumberStringFromBoolean(customField?.customFieldValue)
          : customField?.customFieldValue,
      customFieldConfigOid: customField?.customFieldIdentity
    };

    return dto;
  });
  if (affiliateData?.referrer) {
    const referrerData = {
      userSerialOid: affiliateData?.referrer?.value,
      firstName: '',
      lastName: ''
    };
    affiliateData.referrer = referrerData;
  }
  if (affiliateData?.manager) {
    const managerData = {
      userSerialOid: affiliateData?.manager?.identity
    };
    affiliateData.manager = managerData;
  }
  return affiliateData;
};

export const getCountryName = (countryCode, includeCode = true) => {
  const name = countryMap?.[countryCode?.toString()?.toUpperCase()];

  return !!name ? (includeCode ? `${name} (${countryCode})` : name) : null;
};

export const openDocs = () => {
  window
    .open(`https://dashboard-api.tracknow.io/docs/api-docs`, '_blank')
    .focus();
};
export const openDocsV2 = () => {
  window
    .open(`https://apiv2.tracknow.io/docs/swagger-ui/index.html`, '_blank')
    .focus();
};

export const copyToClipboard = (text, enqueueSnackbar) => {
  navigator.clipboard.writeText(text);
  if (enqueueSnackbar) enqueueSnackbar('Copied to Clipboard');
};

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export const isDevMode = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const getPlatformEmail = customHtml =>
  `<!DOCTYPE html>
<html lang="en" xmlns:th="http://www.thymeleaf.org">
<head>
    <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <style type="text/css">
        body {
            font-family: arial, sans-serif;
            font-weight: normal;
            padding: 0;
            margin: 0;
        }
    </style>

</head>
<body>
<!-- Hero Start -->
<div style=" max-width:600px;" dir="auto">
    <table cellpadding="0" cellspacing="0"
           style="font-size: 15px; font-weight: 400; max-width: 600px; border: none; margin: 0 auto; border-radius: 6px; overflow: hidden; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
        <tbody>
        <tr>
            <td style="padding: 30px 24px 0; ">
               ${customHtml}
            </td>
        </tr>
        </tbody>
    </table>
</div>

</body>
</html>`;

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export function stringToHSL(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  const s = 75 + (hash % 25); // Saturation between 75% and 100%
  const l = 50 + (hash % 20); // Lightness between 50% and 70%
  return `hsl(${h}, ${s}%, ${l}%)`;
}

export function encodeURIOnce(url) {
  try {
    decodeURIComponent(url);
    return url;
  } catch (error) {
    return encodeURI(url);
  }
}

export const setFieldValue = (field, value, filters, setFilters) => {
  const t = cloneDeep(filters);
  t[field] = value;
  setFilters(t);
};

export const isImage = filename => {
  const imagePattern = /\.(png|jpeg|jpg|gif)$/i;

  // Check if the filename matches the pattern
  return imagePattern.test(filename);
};

export const displayUpdateSnackbar = (
  enqueueSnackbar,
  intl,
  sucssess,
  customMessage = null
) => {
  enqueueSnackbar(
    intl.formatMessage({
      id: sucssess ? 'successfully_updated' : customMessage ?? 'general_error'
    }),
    {
      variant: sucssess ? 'success' : 'error',
      autoHideDuration: 2200
    }
  );
};

export const getVisibilityModel = columns =>
  columns.reduce((acc, column) => {
    // Initialize the visibility state of each column based on the 'hide' property
    acc[column.field] = !column.hide;
    return acc;
  }, {});

export const shortUrl = async fullUrl => {
  const response = await post(ApiPaths.shortUrl, { fullUrl });
  return response?.data;
};

export const getShortCampaignUrl = async (
  campaignSerialOid,
  affiliateSerialOid,
  urlParams
) => {
  const response = await get(`${ApiPaths.shortUrl}/campaign-tracking-url`, {
    campaignSerialOid,
    affiliateSerialOid,
    urlParams
  });
  return response?.data;
};

export const calculateComplementaryColor = hexColor => {
  // Remove the # symbol if it's present
  hexColor = hexColor.replace('#', '');

  // Convert the hexadecimal color to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the luminance of the original color
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // Choose black or white as the complementary color based on luminance
  const complementaryColor = luminance > 128 ? '#000000' : '#ffffff';

  return complementaryColor;
};

export const findMissingObject = (arr1, arr2) =>
  arr1?.find(obj1 => !arr2?.some(obj2 => obj1.id === obj2.id));

export function extractLetters(inputString, position, length) {
  if (inputString && inputString.length >= position + length) {
    return inputString.substr(position, length);
  } else {
    return null;
  }
}

export const mergeTranslations = (defaultLanguage, otherLanguages) => {
  //assuming en is def allways
  const mergedTranslations = { en: defaultLanguage };
  // const missing = {};

  // Iterate through keys in enTranslations
  Object.keys(defaultLanguage).forEach(key => {
    // Iterate through each language
    Object.keys(otherLanguages).forEach(lang => {
      // Initialize the language in the result object
      if (!mergedTranslations[lang]) {
        mergedTranslations[lang] = {};
        // missing[lang] = {};
      }

      // if (!otherLanguages[lang][key]) {
      //   missing[lang][key] = defaultLanguage[key];
      // }
      // If the key doesn't exist in the language, use the English translation
      mergedTranslations[lang][key] =
        otherLanguages[lang][key] || defaultLanguage[key];
    });
  });

  // console.log(missing);

  return mergedTranslations;
};

export const replaceTranslations = (mergedTranslations, replaceKeys) => {
  // Iterate through each language
  Object.keys(replaceKeys).forEach(lang => {
    // Initialize the language in the result object
    if (!mergedTranslations[lang]) {
      mergedTranslations[lang] = {};
      // missing[lang] = {};
    }

    // If the key doesn't exist in the language, use the English translation
    Object.keys(replaceKeys[lang]).forEach(key => {
      if (lang == 'en') {
        //in defualt lang we replace all langs to prevent missing keys in spacial translations then if they exists in replaceKeys of the corresponding lang thy will be modified again
        Object.keys(mergedTranslations).forEach(
          tempLang =>
            (mergedTranslations[tempLang][key] = replaceKeys[lang][key])
        );
      } else {
        mergedTranslations[lang][key] = replaceKeys[lang][key];
      }
    });
  });

  // console.log(missing);

  return mergedTranslations;
};

function formatBankAccountInfo(accountInfo) {
  return `Title: ${accountInfo.title ?? ''}
  Bank Name: ${accountInfo.bankName ?? ''}
  Account Number: ${accountInfo.accountNumber ?? ''}
  ABA Number: ${accountInfo.abaNumber ?? ''}
  Account Beneficiary: ${accountInfo.accountBeneficiary ?? ''}
  Bank Branch: ${accountInfo.bankBranch ?? ''}
  Bank City: ${accountInfo.bankCity ?? ''}
  Bank Correspondent: ${accountInfo.bankCorrespondent ?? ''}
  Bank Country: ${accountInfo.bankCountry ?? ''}
  IBAN Number: ${accountInfo.ibanNumber ?? ''}
  SWIFT Code: ${accountInfo.swiftCode ?? ''}
  VAT ID: ${accountInfo.vatId ?? ''}`;
}
export const getPaymentDetails = preferredPayoutSettingsObject => {
  let detailsString = '';
  switch (
    PaymentMethodTypesEnum?.[preferredPayoutSettingsObject?.paymentMethod?.type]
      ?.value
  ) {
    case PaymentMethodTypesEnum.BANK_TRANSFER?.value:
      detailsString = formatBankAccountInfo(preferredPayoutSettingsObject);
      break;
    case PaymentMethodTypesEnum.CUSTOM?.value:
      detailsString = preferredPayoutSettingsObject?.custom;
      break;
    case PaymentMethodTypesEnum.JSON?.value:
      detailsString = 'Title: ' + preferredPayoutSettingsObject?.title + '\n';
      if (preferredPayoutSettingsObject?.jsonData) {
        detailsString += Object.entries(preferredPayoutSettingsObject.jsonData)
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');
      }
      break;

    default:
      detailsString = preferredPayoutSettingsObject?.email;
      break;
  }

  return detailsString ?? '';
};

export const getRandomNumberBetween = (min = 0, max = 10) => {
  return min + Math.floor(Math.random() * (max - min));
};

export const formatAffiliateNameForSelect = affiliate =>
  affiliate
    ? `${
        affiliate?.userSerialOid ? `ID:${affiliate?.userSerialOid}` : ''
      } C:${affiliate?.affiliateCompanyName || ''} ${`E:${affiliate?.email}` ||
        ''}`
    : '';

export const formatManagerNameForSelect = (
  manager,
  idGetter = v => v?.identity
) =>
  manager
    ? `${
        idGetter(manager) ? `ID:${idGetter(manager)}` : ''
      } N:${manager?.firstName || ''} ${manager?.lastName ||
        ''} E:${manager?.email || ''}`
    : '';

const baseImagesUrl = process.env.REACT_APP_SOCIAL_BASE_URL;

export const getFullSocialMediaUrl = url => {
  return baseImagesUrl + url;
};

export function replaceHashtagsWithLinks(inputString, platform) {
  if (!inputString) {
    return inputString;
  }
  // Regular expression to match hashtags
  const hashtagRegex = /#(\w+)/g;

  // Replace hashtags with links
  const replacedString = inputString.replace(hashtagRegex, (match, hashtag) => {
    return platform == SocialPlatformEnum.INSTAGRAM.key
      ? `<a href="https://www.instagram.com/explore/tags/${hashtag}/" target="_blank">#${hashtag}</a>`
      : `<a href="https://www.tiktok.com/tag/${hashtag}/" target="_blank">#${hashtag}</a>`;
  });

  return replacedString.replace(/(\r\n|\n|\r)/g, '<br/>');
}
export const formatNumberCompact = (value, maximumFractionDigits = 2) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2
  }).format(value);

export const openPhoneSupportTab = () =>
  window.open(`https://calendly.com/support-sbe/30min`, '_blank').focus();

export const openChatSupport = () =>
  window.open('https://pages.tracknow.io/chat/1h0ceoj32', '_blank').focus();

export const isUrlDefaultDomain = () => {
  const DOMAIN_NAME = process.env.REACT_APP_DEFAULT_CLEAN_HOST;

  const url = window.location.href;
  const cleanHost = new URL(url).host;
  return cleanHost.toLowerCase() == DOMAIN_NAME.toLowerCase();
};

export const fetchInitialDataIfNotDefaultDomain = () => {
  if (!isUrlDefaultDomain()) {
    store.dispatch(getCompanyInitialData(false, true));
  }
};

export const stopPropagate = callback => {
  return e => {
    e.stopPropagation();
    e.preventDefault();
    callback();
  };
};

export const clearCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
};

export const buildUrlWithParams = (baseUrl, params) => {
  // Create an array to hold the query string components
  const queryString = [];

  if (!params || params == {}) return baseUrl;

  // Loop through the params object and add each key-value pair to the queryString array
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      // Only add the parameter if it has a value
      queryString.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }

  // Join the query string components with '&' and append to the base URL
  const fullUrl =
    queryString.length > 0 ? `${baseUrl}?${queryString.join('&')}` : baseUrl;

  return fullUrl;
};

export const indentHTML = (htmlString, indentSize = 2) => {
  const lines = htmlString.split('\n');
  let indentLevel = 0;
  let indentedHTML = '';

  lines.forEach(line => {
    const trimmedLine = line.trim();

    if (trimmedLine.startsWith('</')) {
      indentLevel--;
    }

    indentedHTML += ' '.repeat(indentSize * indentLevel) + line + '\n';

    if (trimmedLine.startsWith('<') && !trimmedLine.startsWith('</')) {
      indentLevel++;
    }
  });

  return indentedHTML.trim();
};

export const indentStringJSON = jsonString => {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch (error) {
    return jsonString;
  }
};

export const isEmptyHtml = str => {
  if (!str) return true;
  str = str.replace('\n', '');
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  const elements = doc.body.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].textContent.trim()) {
      return false;
    }
  }

  return true;
};

export const formatStartDateFilter = startDate => {
  return startDate != null
    ? moment(startDate)
        .startOf('day')
        ?.toISOString()
    : null;
};

export const formatEndDateFilter = endDate => {
  return endDate != null
    ? moment(endDate)
        .endOf('day')
        ?.toISOString()
    : null;
};

export const calculatePercentage = (all, percentageAmount) => {
  return (percentageAmount / 100) * all;
};

export const isSingleCampaignMode = (affiliate, singleCampaignMode) => {
  if (!affiliate) return false;
  const isSingleModeByDefault =
    singleCampaignMode && affiliate?.userDefaultCampaignEnum !== 'MULTIPLE';

  return affiliate?.singleCampaignModeOverride === null
    ? isSingleModeByDefault
    : affiliate?.singleCampaignModeOverride;
};

export const applyPagingParamsSort = (sort, params) => {
  if (sort && sort?.field) {
    params[`sort`] = `${sort?.field},${sort?.sort}`;
  }
};

export const addMacrosToUrl = (url, macros = {}) => {
  if (!url) return;
  let newUrl = url;
  Object.keys(macros).forEach(key => {
    const regex = new RegExp(`{${key}}`, 'g');
    newUrl = newUrl.replace(regex, macros[key]);
  });

  return newUrl;
};

export const handleDayTime = (date, isStartDate, isEndDate) => {
  if (!date) return null;
  if (isEndDate) {
    return date.clone().endOf('day');
  } else if (isStartDate) {
    return date.clone().startOf('day');
  }
  return date;
};

export const generateMiniListsAsUrlParam = list => {
  if (!list) return null;
  return list.map(i => i?.value).join(',');
};

export const formatGroupByNavBar = (
  item,
  parentValue = '',
  handleChange,
  groupBy,
  intl
) => ({
  label: intl.formatMessage({ id: item?.key }),
  icon: item?.icon ?? '',
  onClick: e =>
    handleChange(
      e,
      parentValue ? `${parentValue}-${item?.value}` : item?.value
    ),
  value: parentValue ? `${parentValue}-${item?.value}` : item?.value,
  scope: item?.scope,
  socialOnly: !!item?.socialMode,
  initialExpandState: groupBy?.includes(item?.value + '-'),
  siteTypes: item?.siteType,
  children: item?.children
    ? Object.values(item.children).map(child =>
        formatGroupByNavBar(child, item?.value, handleChange, groupBy, intl)
      )
    : undefined
});

export const removeUneccecerySlashFromURL = url => {
  return url.replace(/([^:]\/)\/+/g, '$1');
};

/**
 * Converts pagination and sorting models to a Spring Data Pageable object representation.
 *
 * @param {Object} paginationModel - The pagination model containing page and pageSize.
 * @param {Array} sortingModel - The sorting model containing field and sort direction.
 * @param {Object} [mapper] - An optional mapper to map table columns to database columns.
 * @param {string} [defDbColumn] - An optional Default database column to use if a field is not in the mapper when null the column passed will override.
 * @returns {Object} A Pageable object representation suitable for passing to a Spring Data endpoint.
 */
export function toPageable(paginationModel, sortingModel, mapper, defDbColumn) {
  try {
    if (
      !paginationModel ||
      typeof paginationModel.page !== 'number' ||
      typeof paginationModel.pageSize !== 'number'
    ) {
      throw new Error(
        "Invalid pagination model. 'page' and 'pageSize' are required and must be numbers."
      );
    }

    // Map sorting model to Spring Data sort format
    const sort = sortingModel?.map(({ field, sort }) => {
      const dbColumn = (mapper ? mapper[field] || defDbColumn : field) ?? field;
      if (!dbColumn) {
        throw new Error(
          `No mapping found for field '${field}', and no default database column provided.`
        );
      }
      return `${dbColumn},${sort.toUpperCase()}`;
    });

    // Create Pageable object
    const pageable = {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort
    };

    return pageable;
  } catch (error) {
    // Return default pageable in case of any error
    return {
      page: 0,
      size: 10,
      sort: []
    };
  }
}
