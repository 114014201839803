import { useEffect, useState } from 'react';
import { IconButton, Popover } from '@mui/material';
import Iconify from './iconify';
import UserCustomMenuShortcuts from 'src/views/management/Setting/SettingEditOCreateView/tabs/UserCustomMenuShortcuts';

function UserShortcutsPopover() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton color="inherit" size="large" onClick={handleClick}>
        <Iconify icon={'material-symbols:bookmark-outline'} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <UserCustomMenuShortcuts setParentAnchorEl={setAnchorEl} />
      </Popover>
    </>
  );
}

export default UserShortcutsPopover;
